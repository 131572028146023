<template>
  <div class="row-history">
    <el-container class="row-con row-previewOnline">
      <div class="m-previewOnline">
        <ul class="ul-previewOnline">
          <li v-for="(item,index) of onlineList" :key="item.id" :class="{ 'offline': !item.onlineStatus==1 }">
            <div class="inner">
              <div class="top">
                <div class="right">
                  <div class="status">
                    <span class="icon"></span>
                    <span class="value" v-if="item.onlineStatus==1">在线</span>
                    <span class="value" v-else>离线</span>
                  </div>
                  <el-dropdown trigger="click" placement="top-start" @command="handleCommand">
                    <span class="more icon-more">
                    </span>
                    <el-dropdown-menu slot="dropdown" class="g-dropdown">
                      <!-- <el-dropdown-item :command="index + '-' + 0">
                        <span class="icon icon01"></span>
                        <span class="name">运行状态</span>
                      </el-dropdown-item> -->
                      <el-dropdown-item :command="index + '-' + 1">
                        <span class="icon icon02"></span>
                        <span class="name">历史记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 2">
                        <span class="icon icon03"></span>
                        <span class="name">预警信息</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 3">
                        <span class="icon icon04"></span>
                        <span class="name">转场记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 4">
                        <span class="icon icon05"></span>
                        <span class="name">设计参数</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="left">
                  <!--@/assets/images/previewOnline/icon-list01.png -->
                  <div class="pic" :style="{'background-image': `url(${iconImg})`}">
                  </div>
                  <div class="type">{{ item.typeName || '' }}</div>
                  <div class="name" :title="item.name">{{ item.name || '' }}</div>
                </div>
              </div>
              <div class="detail">
                <div class="item">
                  <h4>设备编号</h4>
                  <div class="value">{{ item.key || '' }}</div>
                </div>
                <div class="item">
                  <h4>开始时间</h4>
                  <div class="value">{{ item.realtime|ofiltersPipe('startTime') }}</div>
                </div>
                <div class="item">
                  <h4>记录状态</h4>
                  <div class="value">{{ item.realtime|ofiltersPipe('recordStatus') }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-container>
    <el-dialog :title="`设计参数-${selectItem.key}`" :visible.sync="centerDialogVisible" class="m-pop">
      <div class="m-params-edit">
        <div class="g-notifacition" v-if="showvalue">
          <span class="icon"></span>
          <span class="value">设计参数只有管理员可以修改</span>
        </div>
        <el-form class="form" ref="form" :model="form">
          <el-form-item class="item">
            <el-col :span="24">
              <h3>工程名称</h3>
              <el-input v-model="form.projectName" placeholder="请输入工程名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>施工单位</h3>
              <el-input v-model="form.worker" placeholder="请输入施工单位"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>最短成桩时间(min)</h3>
              <el-input v-model="form.minPileTime" type="number" :min="0" placeholder="请输入最短成桩时间"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计桩长(m)</h3>
              <el-input v-model="form.designDepth" type="number" :min="0" placeholder="请输入设计桩长"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>水泥掺量(kg/m)</h3>
              <el-input v-model="form.designAsh" type="number" :min="0" placeholder="请输入水泥掺量"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>桩间距(m)</h3>
              <el-input v-model="form.designPilePadding" type="number" :min="0" placeholder="请输入桩间距"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>桩径(cm)</h3>
              <el-input v-model="form.pileWidth" type="number" :min="0" placeholder="请输入桩径"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计提钻速度(cm/min)</h3>
              <el-input v-model="form.designUpSpeed" type="number" :min="0" placeholder="请输入设计提钻速度"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>设计下钻速度(cm/min)</h3>
              <el-input v-model="form.designDownSpeed" type="number" :min="0" placeholder="请输入设计下钻速度"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item submit">
            <el-col :span="24">
              <el-button v-if="showSubmit" class="btn submit-btn" type="primary" @click="onSubmit">保存</el-button>
              <el-button class="btn" @click="closePop('centerDialogVisible')">取消</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 页码 -->
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="sizes,slot,  prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="slot , jumper" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import 'videojs-contrib-hls'
import { editConfig } from '@/api/api'
import * as echarts from "echarts"
import moment from "moment";
export default ({
  props: ['onlineList', 'iconImg', 'total', 'currentPage4', 'handleCurrentChange', 'handleSizeChange', 'pageSize', 'toLastPage', 'toFirstPage'],

  data() {
    return {
      showvalue: false,
      showSubmit: true,
      index: 0,
      designDepth: 30,
      deviceKey: '',
      pileId: '',
      fields: '',
      pile: [{
        pileDescribe: "--",
        pileId: ""
      }], //存储桩号
      selectItem: [],
      centerDialogVisible: false,
      form: {
        projectName: '',
        worker: '',
        minPileTime: '',
        designDepth: '',
        designAsh: '',
        designPilePadding: '',
        pileWidth: '',
        designUpSpeed: '',
        designDownSpeed: ''
      },
    }
  },
  filters: {
    ofiltersPipe(val, val2) {
      if (!val) {
        return '-'
      } else {
        if (val[val2] == null) {
          return '-'
        } else {
          return val[val2]
        }
      }
    },
    datafilters(val) {
      if (val == 0) {
        return '停止中'
      } else {
        return moment(val * 1000).format('HH:mm:ss')
      }
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    startTimefilters(val) {
      if (!val) {
        return '-'
      } else {
        if (val.startTime == null) {
          return '-'
        } else {
          return val.startTime
        }
      }
    },
    stafilters(val) {
      switch (val) {
        case '1':
          return '记录中'
        case '2':
          return '暂停'
        case '3':
          return '结束'
        default:
          return val
      }
    },
    sta2filters(val) {
      switch (val) {
        case '1':
          return '喷浆'
        case '0':
          return '止喷'
        default:
          return val
      }
    },
    pilefilter(val) {
      if (val == null) {
        return '--'
      } else {
        return val
      }
    }
  },
  mounted() {
    if (sessionStorage['accountType'] != 1) {
      this.showSubmit = false
      this.showvalue = true
    }
  },
  methods: {
    // 设计参数关闭
    closePop(name) {
      this[name] = false;
    },
    // 保存设计参数
    onSubmit() {
      // let params = {
      //   projectName: "测试工程",
      //   worker: "长杰",
      //   minPileTime: 60.0,
      //   designDepth: 100.0,
      //   designAsh: 5000.0,
      //   designUpSpeed: 199.0,
      //   designDownSpeed: 9999
      // };

      editConfig(this.form, this.selectItem.id).then((res) => {
        this.onlineList[this.index].content = JSON.stringify(this.form)
      })
      this.centerDialogVisible = false;
    },

    // 点击弹出页面
    handleCommand(command) {
      let self = this;
      let index = command.split('-')[0];
      let index2 = command.split('-')[1];
      this.selectItem = this.onlineList[index]; //将具体点击的字段数据存储在selectItem中


      this.index = index
      switch (index2) {
        case '1': {
          sessionStorage['type'] = this.selectItem.type
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/history')
          break;
        }
        case '2': {
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/alertInfo')
          break;
        }
        case '3': {
          this.$router.push('/previewOnline#')
          break;
        }
        case '4': {
          this.centerDialogVisible = true;
          this.form.projectName = JSON.parse(this.selectItem.content).projectName == undefined ? '' : JSON.parse(this.selectItem.content).projectName
          this.form.worker = JSON.parse(this.selectItem.content).worker == undefined ? '' : JSON.parse(this.selectItem.content).worker
          this.form.minPileTime = JSON.parse(this.selectItem.content).minPileTime == undefined ? '' : JSON.parse(this.selectItem.content).minPileTime
          this.form.designDepth = JSON.parse(this.selectItem.content).designDepth == undefined ? '' : JSON.parse(this.selectItem.content).designDepth
          this.form.designAsh = JSON.parse(this.selectItem.content).designAsh == undefined ? '' : JSON.parse(this.selectItem.content).designAsh
          this.form.designPilePadding = JSON.parse(this.selectItem.content).designPilePadding == undefined ? '' : JSON.parse(this.selectItem.content).designPilePadding
          this.form.pileWidth = JSON.parse(this.selectItem.content).pileWidth == undefined ? '' : JSON.parse(this.selectItem.content).pileWidth
          this.form.designUpSpeed = JSON.parse(this.selectItem.content).designUpSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designUpSpeed
          this.form.designDownSpeed = JSON.parse(this.selectItem.content).designDownSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designDownSpeed
          break;
        }
        // default: {

        // }
      }
    },
  }
})
</script>
<style scoped>
/* 轮播图 */
.block {
  background: #000;
  position: relative;
}

.swiper_video {
  height: 50rem;
}

.swiper_item {
  height: 47.3rem;
}

::v-deep .el-carousel__container {
  height: 100%;
}

::v-deep .el-carousel__button {
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

::v-deep .is-active .el-carousel__button {
  background-color: rgba(63, 142, 200, 0.8);
}

.bottom_click {
  position: absolute;
  bottom: 11px;
  color: #fff;
}

.bottom_click1 {
  left: 47.7%;
  transform: translateX(-47.7%);
}

.bottom_click2 {
  left: 50%;
  transform: translateX(-50%);
}

.bottom_click3 {
  left: 52.2%;
  transform: translateX(-52.2%);
}

/* 轮播图结束 */
.m-pop-echart .col,
.m-tab-echartTable {
  height: auto;
}

.m-pop-echart .row {
  display: flex;
}
.m-pop-echart-f01 .inner1 {
  padding-bottom: 32px;
}
.m-box {
  height: 100%;
  padding-top: 1.25rem;
}
.mapDiv {
  /* 地图 */
  width: 100%;
  height: 100%;
}

.ul-previewOnline .inner {
  height: 16rem;
}

.m-pop-echart-f01 .inner1 {
  padding: 1.7rem 2rem 0;
}

.m-datab1 .top {
  margin-bottom: 0;
}

.m-datab1 {
  padding: 0.1rem 1.25rem 0rem;
}

.m-datab1.top {
  padding: 0rem 1.125rem;
}

.m-datab1 .top {
  margin-top: 1.25rem;
}

.m-datab1 {
  padding: 0.1rem 1.25rem 0.75rem;
}

.m-pop-echart .col {
  padding: 0.1rem 0.15rem;
}

.m-statusinfo .bottom .item:first-child {
  width: 20%;
}

/* 详情弹出框桩号右侧的margin值 */
.m-statusinfo .info .item .tit {
  margin-right: 1rem;
}

/* 双头按钮样式 */
.btnpile {
  width: 150px;
}

/* 在线一览 详情 动画效果图上边表格 */
.item_center {
  text-align: center;
}

.m-datab1 .left {
  /*TODO 解决高分屏倾角挤压右侧的问题*/
  margin-right: 1rem !important;
}

/* 在线一览 详情 动画效果图上边表格 样式设置 */
</style>
