<template>
  <div class="row-history">
    <div class="topInput">
      <el-input style="width:40%;marginLeft20px" v-model="inputName" placeholder="请输入内容"></el-input>
      <el-button @click="inputName=''" class="topBtn" type="primary">重置</el-button>
      <el-button @click="searchDevice" class="topBtn" type="primary">查询</el-button>
    </div>
    <el-container class="row-con row-previewOnline">
      <div class="m-previewOnline">
        <ul class="ul-previewOnline">
          <li v-for="(item,index) of onlineList" :key="item.id" :class="{ 'offline': !item.onlineStatus==1 }">
            <div class="inner">
              <div class="top">
                <div class="right">
                  <div class="status">
                    <span class="icon"></span>
                    <span class="value" v-if="item.onlineStatus==1">在线</span>
                    <span class="value" v-else>离线</span>
                  </div>
                  <el-dropdown trigger="click" placement="bottom-start" @command="handleCommand">
                    <span class="more icon-more">
                    </span>
                    <el-dropdown-menu slot="dropdown" class="g-dropdown">
                      <el-dropdown-item :command="index + '-' + 0">
                        <span class="icon icon01"></span>
                        <span class="name">运行状态</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 1">
                        <span class="icon icon02"></span>
                        <span class="name">历史记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 2">
                        <span class="icon icon03"></span>
                        <span class="name">预警信息</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 3">
                        <span class="icon icon04"></span>
                        <span class="name">转场记录</span>
                      </el-dropdown-item>
                      <!-- <el-dropdown-item :command="index + '-' + 4">
                        <span class="icon icon05"></span>
                        <span class="name">设计参数</span>
                      </el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="left">
                  <!--@/assets/images/previewOnline/icon-list01.png -->
                  <div class="pic" :style="{'background-image': `url(${iconImg})`}">
                  </div>
                  <div class="type">{{ item.typeName || '' }}</div>
                  <div class="name" :title='item.name'>{{ item.name || '' }}</div>
                </div>
              </div>
              <div class="detail">
                <div class="item">
                  <h4>设备编号</h4>
                  <div class="value">{{ item.key || '' }}</div>
                </div>
                <div class="item">
                  <h4>开始时间</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('startTime')}}</div>
                </div>
                <div class="item">
                  <h4>记录状态</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('recordStatus')}}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-container>
    <el-dialog :title="`设计参数-${selectItem.key}`" :visible.sync="centerDialogVisible" class="m-pop">
      <div class="m-params-edit">
        <div class="g-notifacition" v-if="showvalue">
          <span class="icon"></span>
          <span class="value">设计参数只有管理员可以修改</span>
        </div>
        <el-form class="form" ref="form" :model="form">
          <el-form-item class="item">
            <el-col :span="24">
              <h3>工程名称</h3>
              <el-input v-model="form.projectName" placeholder="请输入工程名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>施工单位</h3>
              <el-input v-model="form.worker" placeholder="请输入施工单位"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>最短成桩时间(min)</h3>
              <el-input v-model="form.minPileTime" type="number" :min="0" placeholder="请输入最短成桩时间"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计桩长(m)</h3>
              <el-input v-model="form.designDepth" type="number" :min="0" placeholder="请输入设计桩长"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>水泥掺量(kg/m)</h3>
              <el-input v-model="form.designAsh" type="number" :min="0" placeholder="请输入水泥掺量"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>桩间距(m)</h3>
              <el-input v-model="form.designPilePadding" type="number" :min="0" placeholder="请输入桩间距"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>桩径(cm)</h3>
              <el-input v-model="form.pileWidth" type="number" :min="0" placeholder="请输入桩径"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计提钻速度(cm/min)</h3>
              <el-input v-model="form.designUpSpeed" type="number" :min="0" placeholder="请输入设计提钻速度"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>设计下钻速度(cm/min)</h3>
              <el-input v-model="form.designDownSpeed" type="number" :min="0" placeholder="请输入设计下钻速度"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item submit">
            <el-col :span="24">
              <el-button v-if="showSubmit" class="btn submit-btn" type="primary" @click="onSubmit">保存</el-button>
              <el-button class="btn" @click="closePop('centerDialogVisible')">取消</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible1" class="m-pop m-pop-detail" v-el-dialog-drag>
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table  m-tab-echartTable" v-show="tab2 === 0">
          <div class="m-pop-echart m-pop-echart-f01">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner inner1">
                  <!-- <h2>状态信息</h2> -->
                  <div class="m-statusinfo">
                    <div class="top">
                      <h3>{{selectItem.name}}</h3>
                      <div>
                        <div class="info" style="width:16rem;float:left">
                          <div class="item">
                            <div class="tit">作业号</div>
                            <div class="value">{{pile.pileDescribe}}</div>
                          </div>
                          <div class="item">
                            <div class="tit">设备编号</div>
                            <div class="value">{{selectItem.key}}</div>
                          </div>
                        </div>
                        <div class="status" style="width:150px;float:right">
                          <ul class="ul-status">
                            <li :class="recordStatusClass">
                              <div class="btn">{{real.recordStatus|stafilters}}</div>
                            </li>
                          </ul>
                        </div>
                        <div style="clear:both"></div>
                      </div>
                    </div>
                    <div class="bottom left_bottom">
                      <div class="item">
                        <h3>开始时间</h3>
                        <div class="value">{{real.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <h3>作业时长</h3>
                        <div class="value">{{real.lasttime}} min</div>
                      </div>
                      <div class="item">
                        <h3>设计深度</h3>
                        <div class="value">{{real.designPileLength|numfilters}} m</div>
                      </div>
                      <div class="item">
                        <h3>海拔高程</h3>
                        <div class="value">{{real.realAltitude|numfilters}} m</div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <h2>深度时间曲线</h2>
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="m-box m-map">
                  <div class="top">
                    <h2>电子地图</h2>
                    <!-- <div class="right">
                      <g-tabs @change="changeMapHandle" :current="mapType" :list="tabList" />
                    </div> -->
                  </div>
                  <div class="con">
                    <div class="mapDiv">
                      <tdt-map :center="coordinates.center" :zoom="coordinates.zoom" :controls="coordinates.controls">
                        <tdt-marker :position="coordinates.position" :icon="coordinates.icon" :draggable="coordinates.draggable"></tdt-marker>
                        <tdt-control position="topright" :visible="coordinates.visible"></tdt-control>
                      </tdt-map>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <!-- <h2>实时数据</h2> -->
                  <div class="m-datab1">
                    <div class="top">
                      <div class="left">
                        <div class="icon" ref="angle">
                          <!-- <img src="../../assets/images/c-i1.png" alt=""> -->
                        </div>
                        <div class="t1">
                          <p>X<b>{{real.angleX|numfilters(1)}}</b></p>
                          <p>Y<b>{{real.angleY|numfilters(1)}}</b></p>
                        </div>
                      </div>
                      <div class="oh">
                        <div class="item">
                          <p class="item_center">成槽深度</p>
                          <b class="item_center">{{real.maxDepth|numfilters}} m</b>
                        </div>
                        <div class="item">
                          <p class="item_center">抓斗位置</p>
                          <b class="item_center">{{real.depth|numfilters}} m</b>
                        </div>
                        <div class="item">
                          <p class="item_center">单次进尺</p>
                          <b class="item_center">{{real.stepping|numfilters}} m</b>
                        </div>
                        <div class="item">
                          <p class="item_center">抓挖次数</p>
                          <b class="item_center">{{real.rotationNum}} 次</b>
                        </div>
                      </div>
                    </div>
                    <div class="picbox">
                      <!-- :span-method="objectSpanMethod" -->
                      <img src="../../assets/images/c-i3-ARD.png" class="bg" alt="">
                      <div class="databar">
                        <div class="data" :style="{bottom:ztTop}">
                          <img src="../../assets/images/c-i5.png" alt="" :class="image">
                          <img src="../../assets/images/c-i2-ARD.png" alt="" class="zt" style="height:130px">
                          <div class="info">
                            <p><b>{{pileStatusStr}}</b></p>
                            <p>深度<em>{{ real.depth|numfilters }}(m)</em></p>
                            <p>速度<em>{{real.speed|numfilters}}(cm/min)</em></p>
                            <p>拉力<em>{{real.pressure1|numfilters}}(MPa)</em></p>
                            <p>回转角度<em>{{real.rotationAngle|numfilters(2)}}(°)</em></p>
                            <img src="../../assets/images/c-i4.png" alt="" class="line">
                            <p>当前深度<em>{{ real.depth|numfilters }}(m)</em></p>
                            <p>当前高程<em>{{real.realAltitude|numfilters}}(m)</em></p>
                          </div>
                        </div>
                      </div>

                      <div class="boxBotLeft">
                        <b class="item_center">桩顶高程:{{real.realAltitude+real.depth|numfilters}} m</b>
                      </div>
                      <div class="bottomPile">
                        <b class="item_center">设计深度:{{real.designPileLength|numfilters}} m</b>
                        <b class="item_center">桩底高程:{{real.realAltitude+real.depth-real.maxDepth|numfilters}} m</b>
                      </div>

                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="m-tab-table" v-show="tab2 == 1">
        </div>
        <div class="m-tab-table" v-show="tab2 == 2">
        </div>
        <div class="m-tab-table" v-show="tab2 == 3">
          <div class="block">
            <el-carousel trigger="click" :autoplay="false" arrow="never" class="swiper_video">
              <el-carousel-item v-for="(camera,index) in cameraLive" :key="index" class="swiper_item" :label="index+1">
                <video-player style="width:100%;height:100%" ref="videoPlayer" :options="playerOptions[index]"></video-player>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 页码 -->
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="sizes,slot,  prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="slot , jumper" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import { queryDevices } from '@/api/api'
import GPS from '../../utils/GpstoGD'
import { lastData, datas, pileIds, editConfig } from '@/api/api'
import * as echarts from "echarts"
import gTabs from "@/components/gTabs"
import $ from 'jquery'
import moment from "moment";
export default ({
  props: ['onlineList', 'iconImg', 'total', 'currentPage4', 'handleCurrentChange', 'handleSizeChange', 'pageSize', 'toLastPage', 'toFirstPage'],
  components: {
    gTabs
  },
  data() {
    return {
      searchdevList: [],
      totalNum: 0,
      searchDeviceList: [],
      inputName: '',
      showvalue: false,
      showSubmit: true,
      coordinates: {
        center: [0, 0],
        zoom: 11,
        draggable: false,
        position: [0, 0],
        icon: require("@/assets/images/map/b4.png"),
        controls: [
          "Zoom",
          "Scale",
          {
            name: "MapType",
            position: "topright",
            mapTypes: [
              {
                title: "地图", //地图控件上所要显示的图层名称
                icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png", //地图控件上所要显示的图层图标（默认图标大小 80x80）
                layer: "TMAP_NORMAL_MAP" //地图类型，在原天地图api中以window.TMAP_NORMAL_MAP表示，此处为字符串
              },
              {
                title: "卫星",
                icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
                layer: "TMAP_SATELLITE_MAP"
              },
            ]
          }
        ],
        visible: true,
        copyright: true
      },// 天地图
      cameraLive: [],
      playerOptions: [],
      message: String,
      index: 0,
      recordStatusClass: '',
      image: 'stop',
      depth: 0,
      pileStatusStr: '停钻',
      timer: null,
      deviceKey: '',
      pileId: '',
      fields: '',
      pile: [],
      real: {
        'angleX': 0,
        'angleY': 0,
        'beginTime': 0,
        'current': 0,
        'depth': 0,
        'designPileLengths': [],
        'deviceKey': "TP01808020050002",
        'deviceType': "A_RD",
        'deviceTypeName': "管桩",
        'latitude': 0,
        'longitude': 0,
        'maxDepth': 0,
        'pileDescribe': "K313.05.16",
        'pileId': 0,
        'pileLengths': [],
        'pileTimes': [],
        'pressureX': 0,
        'pressure1': 0,
        'pressureY': 0,
        'realTime': 0,
        'recordStatus': 0,
        'recordMode': 0,
        'recordInterval': 0,
        'speed': 0,
        'verticality': 0,
        'x': 0,
        'y': 0,
        'lasttime': 0,
        'rotationAngle': 0
      },
      flow: [], //【时间，流量】
      deep: [], //【时间，深度】
      selectItem: [],
      ztTop: 0,//钻头位置
      y: 0.00,//钻头深度
      scale: 1,
      centerDialogVisible: false,
      form: {
        projectName: '',
        worker: '',
        minPileTime: '',
        designDepth: '',
        designAsh: '',
        designPilePadding: '',
        pileWidth: '',
        designUpSpeed: '',
        designDownSpeed: ''
      },
      centerDialogVisible1: false,
      tab2: 0,
      tab2List: [],
      ispileIds: true
    }
  },
  filters: {
    ofiltersPipe(val, val2) {
      if (!val) {
        return '-'
      } else {
        if (val[val2] == null) {
          return '-'
        } else {
          return val[val2]
        }
      }
    },
    datafilters(val) {
      if (val == 0) {
        return '停止中'
      } else {
        return moment(val).format('HH:mm:ss')
      }
    },
    numfilters(val, par) {
      if (par == undefined) {
        par = 2
      }
      if (isNaN(val)) {
        return val
      } else {
        return parseFloat(val).toFixed(par)
      }
    },
    stafilters(val) {
      switch (val) {
        case 1:
          return '记录中'
        case 0:
          return '暂停'
        default:
          return val
      }
    },
    recordfilters(val) {
      switch (val) {
        case 0:
          return '深度模式'
        case 1:
          return '时间模式'
        default:
          return val
      }
    },
    intervalfilters(val) {
      if (val.recordMode == 0) {
        return val.recordInterval + 'cm'
      } else {
        return val.recordInterval + 's'
      }
    }
  },
  watch: {
    centerDialogVisible1(val, oldVal) {//普通的watch监听
      if (val == false) {
        this.getwatchData()
        if (this.timer) {
          clearInterval(this.timer)
        }
      }
    },
  },
  mounted() {
    if (sessionStorage['accountType'] != 1) {
      this.showSubmit = false
      this.showvalue = true
    }
  },
  methods: {
    searchDevice() {
      queryDevices(this.pageSize, 0, 'A_RD', this.inputName).then(res => {
        this.searchdevList = res.result.records
        this.totalNum = res.result.total
        this.$emit('changeDevicekey', this.inputName, this.searchdevList, this.totalNum)
      });
    },
    // 设置watch中的变量
    getwatchData() {
      this.ispileIds = true
      this.pile = []
      this.real = {
        'angleX': 0,
        'angleY': 0,
        'beginTime': 0,
        'current': 0,
        'depth': 0,
        'designPileLengths': [],
        'deviceKey': "TP01808020050002",
        'deviceType': "A_RD",
        'deviceTypeName': "管桩",
        'latitude': 0,
        'longitude': 0,
        'maxDepth': 0,
        'pileDescribe': "K313.05.16",
        'pileId': 0,
        'pileLengths': [],
        'pileTimes': [],
        'pressureX': 0,
        'pressure1': 0,
        'pressureY': 0,
        'realTime': 0,
        'recordStatus': 0,
        'recordMode': 0,
        'recordInterval': 0,
        'speed': 0,
        'verticality': 0,
        'x': 0,
        'y': 0,
        'lasttime': 0,
        'rotationAngle': 0
      }
      this.flow = []
      this.deep = []
      this.depth = 0
      this.pileStatusStr = '停钻'
      this.recordStatusClass = ''
      this.pipeStatusClass = ''
      if (this.myChart01 === undefined || this.myChart01._disposed) return
      this.myChart01.dispose() //监听弹出框在关闭时将flow和deep里边的数据都清空
      this.image = 'stop'
    },
    setAngle(xAngle, yAngle) {
      let angleOption = {
        tooltip: {
          show: false,
          trigger: "axis",
          formatter: 'x: ' + xAngle + '<br />y :' + yAngle
        },
        grid: {
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        xAxis: {
          type: "value",
          min: "-15",
          max: "15",
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          min: "-15",
          max: "15",
          axisLabel: {
            show: false
          }
        },
        series: [{
          symbolSize: 10,
          data: [
            [xAngle, yAngle]
          ],
          type: 'scatter'
        }]
      };
      if (this.angle == undefined) {
        this.angle = echarts.init(this.$refs.angle);
      }
      this.angle.setOption(angleOption);
    },
    initEchart01(show) {
      let option = {
        title: {
          show: show,
          text: "暂无数据",
          textStyle: {
            color: '#101D34',
            fontSize: 16
          },
          left: "center",
          top: 'center'
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: '23%',
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: '3%',
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m ",
            type: "value",
            inverse: true,  //设置坐标轴翻转
            nameLocation: 'end',
            // max: 30,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: this.deep,
            markPoint: {
              data: [{
                type: "max"
              }]
            }
          },
        ],
      };
      // 绘制图表
      this.myChart01.setOption(option);
    },
    // 在线一览详情头部点击切换不同的模式
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.$nextTick(() => {
          // this.initEchart01();
          window.onresize = function () {
            self.myChart01.resize();
          };
        });
        this.getdata()
      }
      if (index == 1) {
        sessionStorage['type'] = this.selectItem.type
        sessionStorage['deviceKey'] = this.selectItem.key
        this.$router.push('/history')
      }
      if (index == 2) {
        sessionStorage['deviceKey'] = this.selectItem.key
        this.$router.push('/alertInfo')
      }
      if (index == 3) {
        clearInterval(this.timer)
        this.getwatchData()
      }
    },
    // pileIds获取正在施工桩号
    getPileIds() {
      pileIds(this.selectItem.key).then(res => {
        if (res != null) {
          this.pile = res[0]
          this.ispileIds = false
        }
      })
    },
    // 获取data实时数据
    getdata() {
      let self = this
      datas(this.selectItem.key, this.pileId, 'realTime,pressure1,depth').then(res => {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        for (var i = 0; i < res.length; i++) {
          let day_Time = moment(res[i].realTime).format('YYYY-MM-DD HH:mm:ss')
          let deepTime = [];
          deepTime.push(day_Time, Number(res[i].depth).toFixed(2))
          this.deep.push(deepTime) //深度，时间
          // let pressureTime = [];
          // pressureTime.push(day_Time, Number(res[i].pressureY).toFixed(2))
          // this.flow.push(pressureTime) //压力，时间
        }
        // 基于准备好的dom，初始化echarts实例
        this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
        this.initEchart01(false);
        this.timer = setInterval(() => {
          lastData(this.selectItem.key, this.pileId).then(res => {
            if (res != null) {
              if (this.ispileIds == true) {
                this.getPileIds()
              }
              this.real = res
              // console.log(this.real);
              this.setAngle(this.real.angleX, this.real.angleY)
              this.coordinates.position = [Number(this.real.longitude), Number(this.real.latitude)]
              this.coordinates.center = [Number(this.real.longitude), Number(this.real.latitude)]
              if (this.real.recordStatus == 1) {
                this.recordStatusClass = 'on'
              } else if (this.real.recordStatus == 0) {
                this.recordStatusClass = ''
                this.ispileIds = true
              }
              if (this.real.depth > this.depth) {
                this.pileStatusStr = '下钻'
                this.pipeStatusClass = 'on'
                this.image = 'down'
              } else if (this.real.depth < this.depth) {
                this.pileStatusStr = '提钻'
                this.pipeStatusClass = 'on'
                this.image = 'up'
              } else {
                this.pipeStatusClass = ''
              }
              this.depth = this.real.depth
              let realDayTime = moment(res.realTime).format('YYYY-MM-DD HH:mm:ss')
              if (this.deep[this.deep.length - 1][0] !== realDayTime && this.deep[this.deep.length - 1][0] != undefined) {
                let realdeepTime = [];
                realdeepTime.push(realDayTime, Number(res.depth).toFixed(2))
                this.deep.push(realdeepTime)
                // let realpressureTime = [];
                // realpressureTime.push(realDayTime, Number(res.pressureY).toFixed(2))
                // this.flow.push(realpressureTime)
                this.initEchart01(false);
              }
              this.real.lasttime = (((res.realTime - res.beginTime) / 1000) / 60).toFixed(0)
              self.myFun(this.real.depth);
            } else {
              this.ispileIds = true
              this.pile = []
              this.real = {
                'angleX': 0,
                'angleY': 0,
                'beginTime': 0,
                'current': 0,
                'depth': 0,
                'designPileLengths': [],
                'deviceKey': "TP01808020050002",
                'deviceType': "A_RD",
                'deviceTypeName': "管桩",
                'latitude': 0,
                'longitude': 0,
                'maxDepth': 0,
                'pileDescribe': "K313.05.16",
                'pileId': 0,
                'pileLengths': [],
                'pileTimes': [],
                'pressureX': 0,
                'pressure1': 0,
                'pressureY': 0,
                'realTime': 0,
                'recordStatus': 0,
                'recordMode': 0,
                'recordInterval': 0,
                'speed': 0,
                'verticality': 0,
                'x': 0,
                'y': 0,
                'lasttime': 0,
                'rotationAngle': 0
              }
              this.flow = []
              this.deep = []
              this.depth = 0
              this.pileStatusStr = '停钻'
              this.recordStatusClass = ''
              this.pipeStatusClass = ''
              this.initEchart01(true);
              this.image = 'stop'
              self.myFun(this.real.depth)
              this.setAngle(0, 0)
            }
            loading.close();
          })
        }, 3000);
        setTimeout(() => {
          loading.close();
        }, 6000);
      })
    },
    // 点击弹出页面
    handleCommand(command) {
      let self = this;
      let index = command.split('-')[0];
      let index2 = command.split('-')[1];
      this.selectItem = this.onlineList[index]; //将具体点击的字段数据存储在selectItem中
      this.cameraLive = JSON.parse(this.selectItem.cameraLive);
      if (this.cameraLive == null) {
        this.tab2List = [
          {
            name: "运行状况",
          },
          {
            name: "历史记录",
          },
          {
            name: "预警信息",
          }
        ]
      } else {
        this.tab2List = [
          {
            name: "运行状况",
          },
          {
            name: "历史记录",
          }, {
            name: "预警信息",
          }, {
            name: "实时监控",
          }
        ]
        this.playerOptions = [];
        for (let i = 0; i < this.cameraLive.length; i++) {
          let playerOption = {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: true, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
              type: "application/x-mpegURL",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
              src: this.cameraLive[i].liveUrl //url地址
            }],
            notSupportedMessage: '此视频暂无法播放,请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true  //全屏按钮
            }
          }
          this.playerOptions.push(playerOption)
        }
      }

      if (this.selectItem.position == '') {
        this.selectItem.position = '0,0'
      } else {
        this.coordinates.position = [this.selectItem.position.split(',')[0], this.selectItem.position.split(',')[1]]
        this.coordinates.center = [this.selectItem.position.split(',')[0], this.selectItem.position.split(',')[1]]
        if (this.selectItem.onlineStatus == 0) {
          this.coordinates.icon = require("@/assets/images/map/b1.png")
        } else {
          this.coordinates.icon = require("@/assets/images/map/a1.png")
        }
      }
      switch (index2) {
        case '0': {
          this.centerDialogVisible1 = true;
          this.$nextTick(() => {
            // this.initEchart01();
            window.onresize = function () {
              self.myChart01.resize();
            };
          });
          this.getPileIds()
          this.getdata()
          break;
        }
        case '1': {
          sessionStorage['type'] = this.selectItem.type
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/history')
          break;
        }
        case '2': {
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/alertInfo')
          break;
        }
        case '3': {
          this.$router.push('/previewOnline#')
          break;
        }
        case '4': {
          this.centerDialogVisible = true;
          this.form.projectName = JSON.parse(this.selectItem.content).projectName == undefined ? '' : JSON.parse(this.selectItem.content).projectName
          this.form.worker = JSON.parse(this.selectItem.content).worker == undefined ? '' : JSON.parse(this.selectItem.content).worker
          this.form.minPileTime = JSON.parse(this.selectItem.content).minPileTime == undefined ? '' : JSON.parse(this.selectItem.content).minPileTime
          this.form.designDepth = JSON.parse(this.selectItem.content).designDepth == undefined ? '' : JSON.parse(this.selectItem.content).designDepth
          this.form.designAsh = JSON.parse(this.selectItem.content).designAsh == undefined ? '' : JSON.parse(this.selectItem.content).designAsh
          this.form.designPilePadding = JSON.parse(this.selectItem.content).designPilePadding == undefined ? '' : JSON.parse(this.selectItem.content).designPilePadding
          this.form.pileWidth = JSON.parse(this.selectItem.content).pileWidth == undefined ? '' : JSON.parse(this.selectItem.content).pileWidth
          this.form.designUpSpeed = JSON.parse(this.selectItem.content).designUpSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designUpSpeed
          this.form.designDownSpeed = JSON.parse(this.selectItem.content).designDownSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designDownSpeed

          break;
        }
        // default: {

        // }
      }
    },
    // 保存设计参数
    onSubmit() {
      editConfig(this.form, this.selectItem.id).then((res) => {
        this.onlineList[this.index].content = JSON.stringify(this.form)
      })
      this.centerDialogVisible = false;
    },
    // 设计参数关闭
    closePop(name) {
      this[name] = false;
    },
    // 钻头
    myFun(y) {
      var that = this;
      that.y = Number(y).toFixed(2);
      that.ztTop = (100 - y / 40 * 100) + '%';
    },
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
})
</script>
<style scoped>
.boxBotLeft {
  position: absolute;
  bottom: 9.5rem;
  left: 9.5rem;
}
.bottomPile {
  position: absolute;
  bottom: 0.5rem;
  left: 9.5rem;
}
.boxBotLeft .item_center,
.bottomPile .item_center {
  height: 30px;
  line-height: 30px;
  color: #666;
  font-weight: normal;
  font-size: 18px;
}
.picbox .data {
  left: 0.5rem;
}
.block {
  background: #000;
  position: relative;
}

.swiper_video {
  height: 50rem;
}

.swiper_item {
  height: 47.3rem;
}

.mapDiv {
  /* 地图 */
  width: 100%;
  height: 100%;
}
.m-pop-echart .col,
.m-tab-echartTable {
  height: auto;
}

.m-pop-echart .row {
  display: flex;
}

.m-box {
  height: 100%;
}

.m-pop-echart-f01 .inner1 {
  padding-bottom: 32px;
}
/* 在线一览 第一个框底部的表格 */
.m-statusinfo .bottom .item:first-child,
.m-statusinfo .bottom .item:nth-child(2),
.m-statusinfo .bottom .item:nth-child(3) {
  width: 25%;
}
/* .m-statusinfo .bottom .item:nth-child(4),
.m-statusinfo .bottom .item:nth-child(5) {
  width: 50%;
  padding-top: 0.5rem;
  border: 1px solid #dddddd;
  border-left: none;
  text-align: center;
} */
/* .m-statusinfo .bottom .item:nth-child(5) {
  border-right: none;
} */
.item_flow {
  float: left;
  border-top: 1px solid #dddddd;
  width: 20%;
}
.m-statusinfo .bottom h3 {
  margin-bottom: 0.5rem;
}
/* 在线一览 每个模块的高度 */
.ul-previewOnline .inner {
  height: 16rem;
}

/* 在线一览 详情弹出框去除竖向滚动条 */
.m-statusinfo .top {
  padding-bottom: 0.5rem;
}
.m-pop-echart-f01 .inner1 {
  padding: 1.7rem 2rem 0;
}
.m-datab1 .top {
  margin-bottom: 0;
}
.m-datab1 {
  padding: 0.1rem 1.25rem 0rem;
}
.m-datab1.top {
  padding: 0rem 1.125rem;
}
.m-datab1 .top {
  margin-top: 1.25rem;
}
.m-datab1 {
  padding: 0.1rem 1.25rem 0.75rem;
}
.m-pop-echart .col {
  padding: 0.1rem 0.15rem;
}
/*在线一览 详情弹出框去除竖向滚动条结束  */
.item_center {
  text-align: center;
}
</style>