<template>
  <div class="row-history">
    <el-container class="row-con row-previewOnline">
      <div class="m-previewOnline">
        <ul class="ul-previewOnline">
          <li v-for="(item,index) of onlineList" :key="item.id" :class="{ 'offline': !item.onlineStatus==1 }">
            <div class="inner">
              <div class="top">
                <div class="right">
                  <div class="status">
                    <span class="icon"></span>
                    <span class="value" v-if="item.onlineStatus==1">在线</span>
                    <span class="value" v-else>离线</span>
                  </div>
                  <el-dropdown trigger="click" placement="top-start" @command="handleCommand">
                    <span class="more icon-more">
                    </span>
                    <el-dropdown-menu slot="dropdown" class="g-dropdown">
                      <el-dropdown-item :command="index + '-' + 0">
                        <span class="icon icon01"></span>
                        <span class="name">运行状态</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 1">
                        <span class="icon icon02"></span>
                        <span class="name">历史记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 2">
                        <span class="icon icon03"></span>
                        <span class="name">预警信息</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 3">
                        <span class="icon icon04"></span>
                        <span class="name">转场记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 4">
                        <span class="icon icon05"></span>
                        <span class="name">设计参数</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="left">
                  <!--@/assets/images/previewOnline/icon-list01.png -->
                  <div class="pic" :style="{'background-image': `url(${iconImg})`}">
                  </div>
                  <div class="type">{{ item.typeName || '' }}</div>
                  <div class="name" :title='item.name'>{{ item.name || '' }}</div>
                </div>
              </div>
              <div class="detail">
                <div class="item">
                  <h4>设备编号</h4>
                  <div class="value">{{ item.key || '' }}</div>
                </div>
                <div class="item">
                  <h4>记录状态</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('recordStatus')}}</div>
                </div>
                <div class="item">
                  <h4>钻杆状态</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('pipeStatus')}}</div>
                </div>
                <div class="item">
                  <h4>喷灰状态</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('nozzleStatus')}}</div>
                </div>
                <!-- <div class="item">
                  <h4>累计灰量</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('totalAsh')}}
                    <span>{{!item.realtime?'':item.realtime.totalAsh!=nu?'(kg)':''}}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-container>
    <el-dialog :title="`设计参数-${selectItem.key}`" :visible.sync="centerDialogVisible" class="m-pop">
      <div class="m-params-edit">
        <div class="g-notifacition">
          <span class="icon"></span>
          <span class="value">设计参数只有管理员可以修改</span>
        </div>
        <el-form class="form" ref="form" :model="form">
          <el-form-item class="item">
            <el-col :span="24">
              <h3>工程名称</h3>
              <el-input v-model="form.projectName" placeholder="请输入工程名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>施工单位</h3>
              <el-input v-model="form.worker" placeholder="请输入施工单位"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>最短成桩时间(min)</h3>
              <el-input v-model="form.minPileTime" type="number" :min="0" placeholder="请输入最短成桩时间"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计桩长(m)</h3>
              <el-input v-model="form.designDepth" type="number" :min="0" placeholder="请输入设计桩长"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>水泥掺量(kg/m)</h3>
              <el-input v-model="form.designAsh" type="number" :min="0" placeholder="请输入水泥掺量"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>桩间距(m)</h3>
              <el-input v-model="form.designPilePadding" type="number" :min="0" placeholder="请输入桩间距"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>桩径(cm)</h3>
              <el-input v-model="form.pileWidth" type="number" :min="0" placeholder="请输入桩径"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计提钻速度(cm/min)</h3>
              <el-input v-model="form.designUpSpeed" type="number" :min="0" placeholder="请输入设计提钻速度"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>设计下钻速度(cm/min)</h3>
              <el-input v-model="form.designDownSpeed" type="number" :min="0" placeholder="请输入设计下钻速度"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item submit">
            <el-col :span="24">
              <el-button class="btn submit-btn" type="primary" @click="onSubmit">保存</el-button>
              <el-button class="btn" @click="closePop('centerDialogVisible')">取消</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible1" class="m-pop m-pop-detail" v-el-dialog-drag>
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table  m-tab-echartTable" v-show="tab2 === 0">
          <div class="m-pop-echart m-pop-echart-f01 m-pop-echart-two">
            <el-row class="row echart">
              <el-col :span="12" class="col">
                <div class="inner inner1">
                  <!-- <h2>状态信息</h2> -->
                  <div class="m-statusinfo">
                    <div class="top">
                      <h3>{{selectItem.name}}</h3>
                      <div class="info">
                        <div class="item">
                          <div class="tit">桩号</div>
                          <div class="value">{{pile.pileDescribe|pilefilter}}</div>
                        </div>
                        <div class="item">
                          <div class="tit">设备编号</div>
                          <div class="value">{{selectItem.key}}</div>
                        </div>
                      </div>
                      <div class="status">
                        <ul class="ul-status">
                          <li :class="recordStatusClass">
                            <div class="btn">{{StatusPileStarts}}</div>
                          </li>
                          <li :class="pipeStatusClass">
                            <div class="btn">{{pileStatusStr}}</div>
                          </li>
                          <li :class="nozzleStatusClass">
                            <div class="btn">{{StatusAsh}}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="bottom bottomdata">
                      <div class="item">
                        <h3>开始时间</h3>
                        <div class="value">{{StartTime|datafilters}}</div>
                      </div>
                      <!-- <div class="item">
                        <h3>监理</h3>
                        <div class="value">{{real.jianli}}</div>
                      </div> -->
                      <div class="item">
                        <h3>设计桩径</h3>
                        <div class="value">{{real.pileWidth}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <h2>深度、灰量时间曲线</h2>
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <!-- <el-col :span="12" class="col">
                <div class="m-box m-map">
                  <div class="top">
                  </div>
                  <div class="con">
                    <div class="mapDiv">
                      <tdt-map :center="coordinates.center" :zoom="coordinates.zoom" :controls="coordinates.controls">
                        <tdt-marker :position="coordinates.position" :icon="coordinates.icon" :draggable="coordinates.draggable"></tdt-marker>
                        <tdt-control position="topright" :visible="coordinates.visible"></tdt-control>
                      </tdt-map>
                    </div>
                  </div>
                </div>
              </el-col> -->
              <el-col :span="12" class="col">
                <div class="inner">
                  <!-- <h2>实时数据</h2> -->
                  <div class="m-datab1">
                    <div class="top">
                      <div class="left">
                        <div class="icon" ref="angle">
                        </div>
                        <div class="t1">
                          <p>X<b>{{real.xAngle|numfilters(1)}}</b></p>
                          <p>Y<b>{{real.yAngle|numfilters(1)}}</b></p>
                        </div>
                      </div>
                      <div class="oh">
                        <div class="item">
                          <p class="item_center">持续时长</p>
                          <b class="item_center">{{lastTime}}min</b>
                        </div>
                        <div class="item">
                          <p class="item_center">最大深度</p>
                          <b class="item_center">{{MaxDeeps|numfilters}} m</b>
                        </div>
                        <div class="item">
                          <p class="item_center">累计灰量</p>
                          <b class="item_center">- kg</b>
                        </div>
                        <div class="item">
                          <p class="item_center">段灰量</p>
                          <b class="item_center">{{real.ash|numfilters}} kg</b>
                        </div>
                      </div>
                    </div>
                    <div class="picbox">
                      <img src="../../assets/images/c-i3.png" class="bg" alt="">
                      <div class="databar">
                        <div class="data" :style="{bottom:ztTop}">
                          <img src="../../assets/images/c-i5.png" alt="" :class="image">
                          <img src="../../assets/images/c-i2.png" alt="" class="zt">
                          <div class="info">
                            <p><b>{{pileStatusStr}}</b></p>
                            <p>深度<em>{{real.depth|numfilters }}(m)</em></p>
                            <p>速度<em>{{real.speed|numfilters}}(cm/min)</em></p>
                            <p>电流<em>{{real.current|numfilters}}(A)</em></p>
                            <p>喷灰压力<em>{{real.pressure|numfilters}}(Mpa)</em></p>
                            <img src="../../assets/images/c-i4.png" alt="" class="line">
                          </div>
                        </div>
                      </div>

                      <div class="ashEchart">
                        <div style="width:100%;height:100%">
                          <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- <div class="m-tab-table m-tab-addAsh" v-show="tab2 == 3"></div> -->
        <div class="m-tab-table" v-show="tab2 == 1">
        </div>
        <div class="m-tab-table" v-show="tab2 == 2">
        </div>
        <!-- 实时监控 -->
        <div class="m-tab-table" v-show="tab2 == 3">
          <div class="block">
            <el-carousel trigger="click" :autoplay="false" arrow="never" class="swiper_video">
              <el-carousel-item v-for="(camera,index) in cameraLive" :key="index" class="swiper_item" :label="index+1">
                <video-player style="width:100%;height:100%" ref="videoPlayer" :options="playerOptions[index]"></video-player>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 页码 -->
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="sizes,slot, prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="slot,jumper" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import GPS from '../../utils/GpstoGD'
import { lastData, editConfig, datas, pileIds } from '@/api/api'
import * as echarts from "echarts"
import gTabs from "@/components/gTabs"
import moment from "moment";
var player = null;
export default ({
  props: ['onlineList', 'iconImg', 'total', 'currentPage4', 'handleCurrentChange', 'handleSizeChange', 'pageSize', 'toLastPage', 'toFirstPage'],
  components: {
    gTabs
  },
  data() {
    return {
      coordinates: {
        center: [0, 0],
        zoom: 11,
        draggable: false,
        position: [0, 0],
        icon: require("@/assets/images/map/b7.png"),
        controls: [
          "Zoom",
          "Scale",
          {
            name: "MapType",
            position: "topright",
            mapTypes: [
              {
                title: "地图", //地图控件上所要显示的图层名称
                icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png", //地图控件上所要显示的图层图标（默认图标大小 80x80）
                layer: "TMAP_NORMAL_MAP" //地图类型，在原天地图api中以window.TMAP_NORMAL_MAP表示，此处为字符串
              },
              {
                title: "卫星",
                icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
                layer: "TMAP_SATELLITE_MAP"
              },
            ]
          }
        ],
        visible: true,
        copyright: true
      },// 天地图
      cameraLive: [],
      playerOptions: [],
      message: String,
      index: 0,
      lastTime: 0,
      StartTime: '停止中',
      StatusPileStarts: '结束',
      StatusAsh: '止喷',
      image: 'stop',
      pipeStatusClass: '',
      nozzleStatusClass: '',
      recordStatusClass: '',
      depth: 0,
      pileStatusStr: '停钻',
      timer: null,
      deviceKey: '',
      pileId: '',
      pile: [],
      real: { //存实时数据
        'ash': 0,
        'ashedDepth': 0,
        'current': 0,
        'dataType': 1,
        'depth': 0,
        'deviceKey': "2019032201",
        'jianli': "JL00000001",
        'latitude': 33,
        'longitude': 45,
        'pileDescribe': "",
        'pileId': 0,
        'pileWidth': 0,
        'pressure': 0,
        'realTime': 0,
        'speed': 0,
        'storeSeq': 1,
        'userName': "TEST",
        'xAngle': 0,
        'yAngle': 0,
      },
      flow: [], //【时间，流量】
      deep: [], //【时间，深度】
      selectItem: [],
      ztTop: 0,//钻头位置
      y: 0.00,//钻头深度
      scale: 1,
      centerDialogVisible: false,
      form: {
        projectName: '',
        worker: '',
        minPileTime: '',
        designDepth: '',
        designAsh: '',
        designPilePadding: '',
        pileWidth: '',
        designUpSpeed: '',
        designDownSpeed: ''
      },
      centerDialogVisible1: false,
      tab2: 0,
      tab2List: [
        {
          name: "运行状况",
        },
        {
          name: "历史记录",
        },
        {
          name: "预警信息",
        }
      ],
      ispileIds: true,
      MaxDeeps: 0,
      ashErrornum: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      videoCamera: false
    }
  },
  filters: {
    timefilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    pilefilter(val) {
      if (val == null) {
        return '--'
      } else {
        return val
      }
    },
    ofiltersPipe(val, val2) {
      if (!val) {
        return '-'
      } else {
        if (val[val2] == null) {
          return '-'
        } else {
          return val[val2]
        }
      }
    },
    datafilters(val) {
      if (val == '停止中') {
        return '停止中'
      } else {
        return moment(val * 1000).format('HH:mm:ss')
      }
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    }
  },
  watch: {
    centerDialogVisible1(val, oldVal) {//普通的watch监听
      this.tab2 = 0
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (val == false) {
        this.videofalse()
        this.getwatchData()
      }
    },
  },
  methods: {
    // 设置watch中的变量
    getwatchData() {
      this.lastTime = 0
      this.MaxDeeps = 0
      this.ispileIds = true
      this.pile = []
      this.real = {
        'ash': 0,
        'ashedDepth': 0,
        'current': 0,
        'dataType': 1,
        'depth': 0,
        'deviceKey': "2019032201",
        'jianli': "JL00000001",
        'latitude': 33,
        'longitude': 45,
        'pileDescribe': "",
        'pileId': 0,
        'pileWidth': 0,
        'pressure': 0,
        'realTime': 0,
        'speed': 0,
        'storeSeq': 1,
        'userName': "TEST",
        'xAngle': 0,
        'yAngle': 0,
      }
      this.flow = []
      this.deep = []
      this.depth = 0
      this.pileStatusStr = '停钻'
      this.recordStatusClass = ''
      this.pipeStatusClass = ''
      this.nozzleStatusClass = ''
      this.MaxDeeps = 0
      if (this.myChart01 === undefined || this.myChart01._disposed) return
      this.myChart01.dispose() //监听弹出框在关闭时将flow和deep里边的数据都清空
      this.image = 'stop'
    },
    setAngle(xAngle, yAngle) {
      let angleOption = {
        tooltip: {
          show: false,
          trigger: "axis",
          formatter: 'x: ' + xAngle + '<br />y :' + yAngle
        },
        grid: {
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        xAxis: {
          type: "value",
          min: "-15",
          max: "15",
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          min: "-15",
          max: "15",
          axisLabel: {
            show: false
          }
        },
        series: [{
          symbolSize: 10,
          data: [
            [xAngle, yAngle]
          ],
          type: 'scatter'
        }]
      };
      if (this.angle == undefined) {
        this.angle = echarts.init(this.$refs.angle);
      }
      this.angle.setOption(angleOption);
    },
    initEchart01(show) {
      let option = {
        title: {
          show: show,
          text: "暂无数据",
          textStyle: {
            color: '#101D34',
            fontSize: 30,
          },
          left: "center",
          top: 'center',
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "灰量"],
        },
        grid: {
          top: '15%',
          left: "4%",
          right: "4%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: '3%',
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m  ",
            type: "value",
            inverse: true,  //设置坐标轴翻转
            nameLocation: 'end',
            max: 30,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: " 灰量kg",
            type: "value",
            inverse: false,
            nameLocation: 'start',
            // max: 100
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: this.deep,
            markPoint: {
              data: [{
                type: "max"
              }]
            }
          },
          {
            name: "灰量",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: this.flow,
          },
        ],
      }
      // 绘制图表
      this.myChart01.setOption(option);
    },
    initEchart02() {
      let option = {
        title: {
          // text: "暂无数据",
          textStyle: {
            color: '#101D34',
            fontSize: 30,
          },
          left: "center",
          top: 'center',
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: '1%',
          left: "4%",
          right: "1%",
          bottom: "1%",
          containLabel: true,
        },
        yAxis: {
          type: "category",
          data: [0, 1 + 'm', 2 + 'm', 3 + 'm', 4 + 'm', 5 + 'm', 6 + 'm', 7 + 'm', 8 + 'm', 9 + 'm', 10 + 'm', 11 + 'm', 12 + 'm', 13 + 'm', 14 + 'm', 15 + 'm'
            , 16 + 'm', 17 + 'm', 18 + 'm', 19 + 'm', 20 + 'm', 21 + 'm', 22 + 'm', 23 + 'm', 24 + 'm', 25 + 'm', 26 + 'm', 27 + 'm', 28 + 'm', 29 + 'm', 30 + 'm'],
          inverse: true,  //设置坐标轴翻转
          position: 'right'
        },
        xAxis: {
          type: 'value',
          axisLine: {
            show: true
          },
          inverse: true
        },
        series: [
          {
            // data: [0, 99, 12, 34, 89, 45, 20, 39, 78, 56, 99, 78, 56, 42, 30, 69, 87, 43, 67, 21, 58, 95, 58, 123, 19, 82, 66, 88, 70, 90],
            data: this.ashErrornum,
            type: 'line',
            showSymbol: false,
          }
        ],
      }
      // 绘制图表
      this.myChart02.setOption(option);
    },
    // 在线一览详情头部点击切换不同的模式
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.$nextTick(() => {
          window.onresize = function () {
            self.myChart01.resize();
          };
        });
        this.getData()
        this.videofalse()
      }
      if (index == 1) {
        sessionStorage['type'] = this.selectItem.type
        sessionStorage['deviceKey'] = this.selectItem.key
        this.$router.push('/history')
        this.videofalse()
        clearInterval(this.timer)
      }
      if (index == 2) {
        sessionStorage['deviceKey'] = this.selectItem.key
        this.$router.push('/alertInfo')
        this.videofalse()
        clearInterval(this.timer)
      }
      if (index == 3) {
        if (this.timer) {
          clearInterval(this.timer)
          this.getwatchData()
        }
        this.video()
      }
    },
    //  pileIds正在施工桩号
    getPileIds() {
      pileIds(this.selectItem.key).then(res => {
        if (res != null) {
          this.pile = res[0]
          this.ispileIds = false
        }
      })
    },
    // data获取实时数据列表
    getData() {
      let self = this;
      var MaxDeep = []
      datas(this.selectItem.key, '', 'realTime,depth,ash').then(res => {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        for (var i = 0; i < res.length; i++) {
          MaxDeep.push(res[i].depth)
          let day_Time = moment(res[i].realTime * 1000).format('YYYY-MM-DD HH:mm:ss')
          let flowTime = [];
          let deepTime = [];
          flowTime.push(day_Time, res[i].ash.toFixed(2))
          deepTime.push(day_Time, res[i].depth.toFixed(2))
          this.flow.push(flowTime)
          this.deep.push(deepTime)

          if (this.ashErrornum[Math.ceil(res[i].depth - 1)] == undefined) {
            this.ashErrornum[Math.ceil(res[i].depth - 1)] = 0
          }
          this.ashErrornum[Math.ceil(res[i].depth - 1)] += Number(res[i].ash.toFixed(2))

        }
        if (MaxDeep == '') {
          this.MaxDeeps = 0
        } else {
          this.MaxDeeps = Math.max(...MaxDeep)
        }
        // 基于准备好的dom，初始化echarts实例
        this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
        this.initEchart01(false);
        this.myChart02 = echarts.init(this.$refs.myChart02, null, { renderer: 'svg' });
        this.initEchart02();
        // 获取数组中的最后一个值
        var end = res[res.length - 1]
        if (end == undefined) {
          this.StatusAsh = '止喷'
          this.nozzleStatusClass = ''
        } else {
          this.depth = end.depth
          this.StartTime = res[0].realTime
          this.lastTime = ((end.realTime - res[0].realTime) / 60).toFixed(1)
          self.myFun(end.deep);
        }
        this.timer = setInterval(() => {
          lastData(this.selectItem.key, this.pileId).then(res => {
            if (res != null) {
              if (this.ispileIds == true) {
                this.getPileIds()
              }
              this.real = res
              if (res.depth > this.MaxDeeps) {
                this.MaxDeeps = res.depth
              }
              this.setAngle(this.real.xAngle, this.real.yAngle)
              this.coordinates.position = [res.longitude, res.latitude]
              this.coordinates.center = [res.longitude, res.latitude]

              if (this.real != null) {
                this.recordStatusClass = 'on'
                this.StatusPileStarts = '开始'
              } else {
                this.recordStatusClass = ''
                this.StatusPileStarts = '结束'
                this.ispileIds = true
              }
              if (this.real.ash != 0) {
                this.StatusAsh = '喷灰'
                this.nozzleStatusClass = 'on'
              } else {
                this.StatusAsh = '止喷'
                this.nozzleStatusClass = ''
              }

              if (this.real.speed > 0) {
                this.pileStatusStr = '下钻'
                this.pipeStatusClass = 'on'
                this.image = 'down'
              } else if (this.real.speed < 0) {
                this.pileStatusStr = '提钻'
                this.pipeStatusClass = 'on'
                this.image = 'up'
              }
              // this.depth = this.real.depth
              let realDayTime = moment(res.realTime * 1000).format('YYYY-MM-DD HH:mm:ss')
              if (this.flow.length == 0 || this.flow[this.flow.length - 1][0] !== realDayTime) {
                let realflowTime = [];
                let realdeepTime = [];
                realflowTime.push(realDayTime, res.ash.toFixed(2))
                realdeepTime.push(realDayTime, res.depth.toFixed(2))
                this.flow.push(realflowTime)
                this.deep.push(realdeepTime)
                this.initEchart01(false);
              }
              self.myFun(this.real.depth)
            } else {
              this.ispileIds = true
              this.pile = []
              this.real = //存储实时数据
              {
                'ash': 0,
                'ashedDepth': 0,
                'current': 0,
                'dataType': 1,
                'depth': 0,
                'deviceKey': "2019032201",
                'jianli': "JL00000001",
                'latitude': 33,
                'longitude': 45,
                'pileDescribe': "",
                'pileId': 0,
                'pileWidth': 0,
                'pressure': 0,
                'realTime': 0,
                'speed': 0,
                'storeSeq': 1,
                'userName': "TEST",
                'xAngle': 0,
                'yAngle': 0,
              }
              this.flow = []
              this.deep = []
              this.depth = 0
              this.pileStatusStr = '停钻'
              this.recordStatusClass = ''
              this.pipeStatusClass = ''
              this.nozzleStatusClass = ''
              this.initEchart01(true);
              this.image = 'stop'
              this.myFun(this.real.depth)
            }
            loading.close();
          })
        }, 3000);
      })
    },
    // video视频
    video() {
      this.playerOptions = [];
      for (let i = 0; i < this.cameraLive.length; i++) {
        let playerOption = {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: true, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "application/x-mpegURL",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.cameraLive[i].liveUrl
            // src: "https://live-hls-tums.tplinkcloud.com.cn:8100/store/8422fd257b3a24cb/0/af61c733/playlist.m3u8?uuid=af61c733" //url地址
          }],
          notSupportedMessage: '此视频暂无法播放,请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }
        this.playerOptions.push(playerOption)
      }
    },
    // 视频不播放
    videofalse() {
      this.playerOptions = [{
        sources: [{
          type: "application/x-mpegURL",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: "" //url地址
        }],
        notSupportedMessage: '此视频暂无法播放,请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      }]
    },
    // 点击详情弹出页面
    handleCommand(command) {
      let self = this;
      let index = command.split('-')[0];
      let index2 = command.split('-')[1];
      this.selectItem = this.onlineList[index]; //将具体点击的字段数据存储在selectItem中
      // 获取实时监控数据
      this.cameraLive = JSON.parse(this.selectItem.cameraLive);
      if (this.cameraLive != null) {
        this.tab2List = [
          {
            name: "运行状况",
          },
          {
            name: "历史记录",
          },
          {
            name: "预警信息",
          },
          {
            name: "实时监控"
          }
        ]
        this.videofalse()
      }

      if (this.selectItem.position == '') {
        this.selectItem.position = '0,0'
      } else {
        this.coordinates.position = [this.selectItem.position.split(',')[0], this.selectItem.position.split(',')[1]]
        this.coordinates.center = [this.selectItem.position.split(',')[0], this.selectItem.position.split(',')[1]]
        if (this.selectItem.onlineStatus == 0) {
          this.coordinates.icon = require("@/assets/images/map/b7.png")
        } else {
          this.coordinates.icon = require("@/assets/images/map/a6.png")
        }
      }
      switch (index2) {
        case '0': {
          this.centerDialogVisible1 = true;
          // this.$nextTick(() => {
          //   // this.initEchart01();
          //   window.onresize = function () {
          //     self.myChart01.resize();
          //   };
          // });
          //  pileIds正在施工桩号
          this.getPileIds()
          this.getData()
          break;
        }
        case '1': {
          sessionStorage['type'] = this.selectItem.type
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/history')
          break;
        }
        case '2': {
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/alertInfo')
          break;
        }
        case '3': {
          this.$router.push('/previewOnline#')
          break;
        }
        case '4': {
          this.centerDialogVisible = true;
          this.form.projectName = JSON.parse(this.selectItem.content).projectName == undefined ? '' : JSON.parse(this.selectItem.content).projectName
          this.form.worker = JSON.parse(this.selectItem.content).worker == undefined ? '' : JSON.parse(this.selectItem.content).worker
          this.form.minPileTime = JSON.parse(this.selectItem.content).minPileTime == undefined ? '' : JSON.parse(this.selectItem.content).minPileTime
          this.form.designDepth = JSON.parse(this.selectItem.content).designDepth == undefined ? '' : JSON.parse(this.selectItem.content).designDepth
          this.form.designAsh = JSON.parse(this.selectItem.content).designAsh == undefined ? '' : JSON.parse(this.selectItem.content).designAsh
          this.form.designPilePadding = JSON.parse(this.selectItem.content).designPilePadding == undefined ? '' : JSON.parse(this.selectItem.content).designPilePadding
          this.form.pileWidth = JSON.parse(this.selectItem.content).pileWidth == undefined ? '' : JSON.parse(this.selectItem.content).pileWidth
          this.form.designUpSpeed = JSON.parse(this.selectItem.content).designUpSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designUpSpeed
          this.form.designDownSpeed = JSON.parse(this.selectItem.content).designDownSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designDownSpeed
          break;
        }
        // default: {

        // }
      }
    },
    destroyed() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    // 保存设计参数
    onSubmit() {
      editConfig(this.form, this.selectItem.id).then((res) => {
        this.onlineList[this.index].content = JSON.stringify(this.form)
      })
      this.centerDialogVisible = false;
    },
    // 设计参数关闭
    closePop(name) {
      this[name] = false;
    },
    // 钻头
    myFun(y) {
      var that = this;
      that.y = Number(y).toFixed(2);
      that.ztTop = (100 - y / 40 * 100) + '%';
    },
  }
})
</script>
<style scoped>
/* 灰量图 */
.picbox {
  position: relative;
}
.ashEchart {
  position: absolute;
  height: 364px;
  width: 310px;
  /* background: rgb(4, 93, 120); */
  bottom: 0px;
  left: 0px;
}
/* 视频样式 */
.block {
  background: #000;
  position: relative;
}

.swiper_video {
  height: 50rem;
}

.swiper_item {
  height: 47.3rem;
}

::v-deep .el-carousel__container {
  height: 100%;
}

::v-deep .el-carousel__button {
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

::v-deep .is-active .el-carousel__button {
  background-color: rgba(63, 142, 200, 0.8);
}
.bottom_click {
  position: absolute;
  bottom: 11px;
  color: #fff;
}

.bottom_click1 {
  left: 47.7%;
  transform: translateX(-47.7%);
}

.bottom_click2 {
  left: 50%;
  transform: translateX(-50%);
}

.bottom_click3 {
  left: 52.2%;
  transform: translateX(-52.2%);
}
/* 视频样式end */

.m-statusinfo .bottomdata {
  display: flex;
  justify-content: space-between;
}
.m-pop-detail .dialog-header .left .g-tabs {
  width: 26.75rem;
}
.mapDiv {
  /* 地图 */
  width: 100%;
  height: 100%;
}
.m-box {
  height: 100%;
  /* padding-top: 1.25rem; */
}
.m-pop-echart .col,
.m-tab-echartTable {
  height: auto;
}

.m-pop-echart .row {
  display: flex;
}
.m-pop-echart-f01 .inner1 {
  padding-bottom: 32px;
}
.ul-previewOnline .inner {
  height: 332px;
}
.m-statusinfo .bottom .item {
  width: 25%;
}
/* 在线一览 详情弹出框去除竖向滚动条 */
.ul-previewOnline .inner {
  height: 362px;
}
.m-pop-echart-f01 .inner1 {
  padding: 1.7rem 2rem 0;
}
.m-pop-echart-f01 h2 {
  line-height: 40px;
}
.m-datab1 .top {
  margin-bottom: 0;
}
.m-datab1 {
  padding: 0.1rem 1.25rem 0rem;
}
.m-datab1.top {
  padding: 0rem 1.125rem;
}
.m-datab1 .top {
  margin-top: 1.25rem;
}
.m-datab1 {
  padding: 0.1rem 1.25rem 0.75rem;
}
.m-pop-echart .col {
  padding: 0.1rem 0.15rem;
}
.item_center {
  text-align: center;
}

/* 实时样式 */
.m-pop-echart-two {
  display: flex;
  flex-direction: row;
}
.echart {
  display: flex;
  flex-direction: column;
}
.echart .col {
  height: 100%;
}

.m-pop-echart-two .el-col-12 {
  width: 100%;
}

.m-datab1 .picbox .bg {
  height: 680px;
}
.picbox .databar {
  height: 354px;
}
.picbox .zt {
  height: 325px;
}
</style>
