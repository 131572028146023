<template>
  <div class="row-history">
    <el-container class="row-con row-previewOnline">
      <div class="m-previewOnline">
        <ul class="ul-previewOnline">
          <li v-for="(item,index) of onlineList" :key="item.id" :class="{ 'offline': !item.onlineStatus==1 }">
            <div class="inner">
              <div class="top">
                <div class="right">
                  <div class="status">
                    <span class="icon"></span>
                    <span class="value" v-if="item.onlineStatus==1">在线</span>
                    <span class="value" v-else>离线</span>
                  </div>
                  <el-dropdown trigger="click" placement="top-start" @command="handleCommand">
                    <span class="more icon-more">
                    </span>
                    <el-dropdown-menu slot="dropdown" class="g-dropdown">
                      <el-dropdown-item :command="index + '-' + 1">
                        <span class="icon icon02"></span>
                        <span class="name">历史记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 2">
                        <span class="icon icon03"></span>
                        <span class="name">预警信息</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 3">
                        <span class="icon icon04"></span>
                        <span class="name">转场记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 4">
                        <span class="icon icon05"></span>
                        <span class="name">设计参数</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="left">
                  <!--@/assets/images/previewOnline/icon-list01.png -->
                  <div class="pic" :style="{'background-image': `url(${iconImg})`}">
                  </div>
                  <div class="type">{{ item.typeName || '' }}</div>
                  <div class="name" :title='item.name'>{{ item.name || '' }}</div>
                </div>
              </div>
              <div class="detail">
                <div class="item">
                  <h4>设备编号</h4>
                  <div class="value">{{ item.key || '' }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-container>
    <el-dialog :title="`设计参数-${selectItem.key}`" :visible.sync="centerDialogVisible" class="m-pop">
      <div class="m-params-edit">
        <div class="g-notifacition" v-if="showvalue">
          <span class="icon"></span>
          <span class="value">设计参数只有管理员可以修改</span>
        </div>
        <el-form class="form" ref="form" :model="form">
          <el-form-item class="item">
            <el-col :span="24">
              <h3>工程名称</h3>
              <el-input v-model="form.name" placeholder="请输入工程名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>施工单位</h3>
              <el-input v-model="form.company" placeholder="请输入施工单位"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>最短成桩时间(min)</h3>
              <el-input v-model="form.min" type="number" :min="0" placeholder="请输入最短成桩时间"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计桩长(m)</h3>
              <el-input v-model="form.length" type="number" :min="0" placeholder="请输入设计桩长"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>水泥掺量(kg/m)</h3>
              <el-input v-model="form.weight" type="number" :min="0" placeholder="请输入水泥掺量"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>桩间距(m)</h3>
              <el-input v-model="form.margin" type="number" :min="0" placeholder="请输入桩间距"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>桩径(cm)</h3>
              <el-input v-model="form.radius" type="number" :min="0" placeholder="请输入桩径"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计提钻速度(cm/min)</h3>
              <el-input v-model="form.upspeed" type="number" :min="0" placeholder="请输入设计提钻速度"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>设计下钻速度(cm/min)</h3>
              <el-input v-model="form.downspeed" type="number" :min="0" placeholder="请输入设计下钻速度"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item submit">
            <el-col :span="24">
              <el-button v-if="showSubmit" class="btn submit-btn" type="primary" @click="onSubmit">保存</el-button>
              <el-button class="btn" @click="closePop('centerDialogVisible')">取消</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 页码 -->
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="sizes,slot, prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="slot , jumper" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import $ from 'jquery'
import moment from "moment";
export default ({
  props: ['onlineList', 'iconImg', 'total', 'currentPage4', 'handleCurrentChange', 'handleSizeChange', 'pageSize', 'toLastPage', 'toFirstPage'],
  data() {
    return {
      showvalue: false,
      showSubmit: true,
      timer: null,
      deviceKey: '',
      pileId: '',
      fields: '',
      selectItem: [],
      centerDialogVisible: false,
      form: {
        name: '',
        company: '',
        min: '',
        length: '',
        weight: '',
        margin: '',
        radius: '',
        upspeed: '',
        downspeed: ''
      },
    }
  },
  mounted() {
    if (sessionStorage['accountType'] != 1) {
      this.showSubmit = false
      this.showvalue = true
    }
  },
  methods: {
    // 点击弹出页面
    handleCommand(command) {
      let self = this;
      let index = command.split('-')[0];
      let index2 = command.split('-')[1];
      this.selectItem = this.onlineList[index]; //将具体点击的字段数据存储在selectItem中

      switch (index2) {
        case '1': {
          sessionStorage['type'] = this.selectItem.type
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/history')
          break;
        }
        case '2': {
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/alertInfo')
          break;
        }
        case '3': {
          this.$router.push('/previewOnline#')
          break;
        }
        case '4': {
          this.centerDialogVisible = true;
          break;
        }
        // default: {

        // }
      }
    },
    destroyed() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    onSubmit() {
    },
  }
})
</script>
<style scoped>
.m-pop-echart .col,
.m-tab-echartTable {
  height: auto;
}

.m-pop-echart .row {
  display: flex;
}

.m-box {
  height: 100%;
}

.m-pop-echart-f01 .inner1 {
  padding-bottom: 32px;
}
#allmap {
  height: 100%;
  width: 100%;
}
.ul-previewOnline .inner {
  height: 15rem;
}
</style>
