<template>
  <div class="row-history">
    <!-- m-topNav -->
    <div class="m-topNav">
      <el-tabs @tab-click="handleClick" v-model="tab">
        <el-tab-pane v-for="item of tabList" :key="item.name" :label="item.label" :name="item.name">
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- m-topNav end -->
    <!-- row-con -->
    <component @changeDevicekey="changeDevicekey" :is="tab" :iconImg="iconImg" :onlineList="onlineList" :pageSize="pageSize" :total="total" :currentPage4="currentPage4" :handleCurrentChange="handleCurrentChange" :handleSizeChange="handleSizeChange" :toLastPage="toLastPage" :toFirstPage="toFirstPage"></component>
    <!-- row-con end -->
  </div>
</template>

<script>
import gTabs from "@/components/gTabs"
import $ from 'jquery'
import { queryDevices } from '@/api/api'
import { queryGroups } from '@/api/api'
import moment from 'moment'
import JBZ from '@/views/onLine/JBZ'
import GH from '@/views/onLine/GH'
import GYXPZ from '@/views/onLine/GYXPZ'
import GFZ from '@/views/onLine/GFZ'
import GZ from '@/views/onLine/GZ'
import GJ from '@/views/onLine/GJ'
import CGSZ from '@/views/onLine/CGSZ'
import CP from '@/views/onLine/CP'
import HTZJ from '@/views/onLine/HTZJ'
import FPZ from '@/views/onLine/FPZ'
import DK from '@/views/onLine/DK'
import CFG from '@/views/onLine/CFG'
import XWZ from '@/views/onLine/XWZ'
import ZKFPZ from '@/views/onLine/ZKFPZ'
import DP from "@/views/onLine/DP"
import LG from "@/views/onLine/LG"
import SP from "@/views/onLine/SP"
import A_RD from "@/views/onLine/A_RD"
export default {
  name: 'previewOnline',
  components: {
    gTabs,
    JBZ, GH, GYXPZ, GFZ, GZ, GJ, CGSZ, CP, HTZJ, FPZ, DK, CFG, XWZ, ZKFPZ, DP, LG, SP, A_RD
  },
  data() {
    return {
      tab: 'JBZ',
      iconImg: '',
      pageSize: 8,
      total: 0,
      currentPage4: 1, //当前页码数
      tabList: [
        {
          label: '固化土',
          name: '0'
        },
        {
          label: '粉喷桩',
          name: '1'
        },
        {
          label: '高压旋喷桩',
          name: '2'
        },
        {
          label: '工法桩',
          name: '3'
        },
        {
          label: '沉管砂桩',
          name: '4'
        },
        {
          label: '搅拌桩',
          name: '5'
        }
      ],
      onlineList: [
        {
          id: '1',
          icon: require('@/assets/images/previewOnline/icon-list01.png'),
          typeName: "搅拌桩",
          name: '双头搅拌桩测试',
          key: "MX02808020020100",
          startTime: '2021-04-14 10:34',
          createAt: 1540102966,
          online: true,
          status: 1,
          zuangan: '下钻',
          penhui: '喷灰',
          weight: 126.26
        },
      ],
      selectItem: {},
      inputdeviceName: ''
    }
  },
  mounted() {
    queryGroups().then(res => {
      this.tabList = res
      if (sessionStorage['type'] != undefined) {
        this.tab = sessionStorage['type']
      } else {
        this.tab = this.tabList[0].name
      }
      this.iconImg = require('@/assets/images/previewOnline/' + this.tab + '.png')
      queryDevices(this.pageSize, 0, this.tab).then(res => {
        this.onlineList = res.result.records
        this.total = res.result.total
      });
    })
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    // 接受子页面传的数据
    changeDevicekey(inputName, searchdevList, totalNum) {
      this.inputdeviceName = inputName
      this.onlineList = searchdevList
      this.total = totalNum
    },

    handleSizeChange(val) {
      this.pageSize = val
      queryDevices(this.pageSize, 0, this.tab, this.inputdeviceName).then(res => {
        this.onlineList = res.result.records
        this.total = res.result.total
      })
    },
    handleCurrentChange(val) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.currentPage4 = val
      queryDevices(this.pageSize, this.currentPage4, this.tab, this.inputdeviceName).then(res => {
        this.onlineList = res.result.records
        this.total = res.result.total
        loading.close();
      })
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    toFirstPage() {
      this.handleCurrentChange(1)
    },
    toLastPage() {
      this.handleCurrentChange(Math.ceil(this.total / this.pageSize))
    },
    // 点击切换事件
    handleClick() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.iconImg = require('@/assets/images/previewOnline/' + this.tab + '.png')
      this.currentPage4 = 1;
      queryDevices(this.pageSize, 0, this.tab).then(res => {
        this.onlineList = res.result.records
        this.total = res.result.total
        sessionStorage['type'] = this.tab
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
  }
}
</script>

<style scoped>
.m-pop-echart .col,
.m-tab-echartTable {
  height: auto;
}
.m-pop-echart .row {
  display: flex;
}
.m-box {
  height: 100%;
}
.m-pop-echart-f01 .inner1 {
  padding-bottom: 32px;
}
</style>
