<template>
  <div class="row-history">
    <el-container class="row-con row-previewOnline">
      <div class="m-previewOnline">
        <ul class="ul-previewOnline">
          <li v-for="(item,index) of onlineList" :key="item.id" :class="{ 'offline': !item.onlineStatus==1 }">
            <div class="inner">
              <div class="top">
                <div class="right">
                  <div class="status">
                    <span class="icon"></span>
                    <span class="value" v-if="item.onlineStatus==1">在线</span>
                    <span class="value" v-else>离线</span>
                  </div>
                  <el-dropdown trigger="click" placement="top-start" @command="handleCommand">
                    <span class="more icon-more">
                    </span>
                    <el-dropdown-menu slot="dropdown" class="g-dropdown">
                      <el-dropdown-item :command="index + '-' + 0">
                        <span class="icon icon01"></span>
                        <span class="name">运行状态</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 1">
                        <span class="icon icon02"></span>
                        <span class="name">历史记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 2">
                        <span class="icon icon03"></span>
                        <span class="name">预警信息</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 3">
                        <span class="icon icon04"></span>
                        <span class="name">转场记录</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="index + '-' + 4">
                        <span class="icon icon05"></span>
                        <span class="name">设计参数</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="left">
                  <!--@/assets/images/previewOnline/icon-list01.png -->
                  <div class="pic" :style="{'background-image': `url(${iconImg})`}">
                  </div>
                  <div class="type">{{ item.typeName || '' }}</div>
                  <div class="name" :title='item.name'>{{ item.name || '' }}</div>
                </div>
              </div>
              <div class="detail">
                <div class="item">
                  <h4>设备编号</h4>
                  <div class="value">{{ item.key || '' }}</div>
                </div>
                <div class="item">
                  <h4>开始时间</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('startTime')}}</div>
                </div>
                <div class="item">
                  <h4>记录状态</h4>
                  <div class="value">{{item.realtime|ofiltersPipe('recordStatus')}}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-container>
    <el-dialog :title="`设计参数-${selectItem.key}`" :visible.sync="centerDialogVisible" class="m-pop">
      <div class="m-params-edit">
        <div class="g-notifacition" v-if="showdesigndiv">
          <span class="icon"></span>
          <span class="value">设计参数只有管理员可以修改</span>
        </div>
        <el-form class="form" ref="form" :model="form">
          <el-form-item class="item">
            <el-col :span="24">
              <h3>工程名称</h3>
              <el-input v-model="form.projectName" placeholder="请输入工程名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>施工单位</h3>
              <el-input v-model="form.worker" placeholder="请输入施工单位"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>最短成桩时间(min)</h3>
              <el-input v-model="form.minPileTime" type="number" :min="0" placeholder="请输入最短成桩时间"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计桩长(m)</h3>
              <el-input v-model="form.designDepth" type="number" :min="0" placeholder="请输入设计桩长"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>水泥掺量(kg/m)</h3>
              <el-input v-model="form.designAsh" type="number" :min="0" placeholder="请输入水泥掺量"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>桩间距(m)</h3>
              <el-input v-model="form.designPilePadding" type="number" :min="0" placeholder="请输入桩间距"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>桩径(cm)</h3>
              <el-input v-model="form.pileWidth" type="number" :min="0" placeholder="请输入桩径"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item">
            <el-col :span="12">
              <h3>设计提钻速度(cm/min)</h3>
              <el-input v-model="form.designUpSpeed" type="number" :min="0" placeholder="请输入设计提钻速度"></el-input>
            </el-col>
            <el-col :span="12">
              <h3>设计下钻速度(cm/min)</h3>
              <el-input v-model="form.designDownSpeed" type="number" :min="0" placeholder="请输入设计下钻速度"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item class="item submit">
            <el-col :span="24">
              <el-button v-if="showSubmit" class="btn submit-btn" type="primary" @click="onSubmit">保存</el-button>
              <el-button class="btn" @click="closePop('centerDialogVisible')">取消</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible1" class="m-pop m-pop-detail" v-el-dialog-drag>
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table  m-tab-echartTable" v-show="tab2 === 0">
          <div class="m-pop-echart m-pop-echart-f01">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner inner1">
                  <!-- <h2>状态信息</h2> -->
                  <div class="m-statusinfo">
                    <div class="top">
                      <h3>{{selectItem.name}}</h3>
                      <div class="info">
                        <div class="item">
                          <div class="tit">桩号</div>
                          <div class="value">{{pile.pileDescribe}}</div>
                        </div>
                        <div class="item">
                          <div class="tit">设备编号</div>
                          <div class="value">{{selectItem.key}}</div>
                        </div>
                      </div>
                      <div class="status">
                        <ul class="ul-status">
                          <li :class="recordStatusClass">
                            <div class="btn">{{real.recordStatus|stafilters}}</div>
                          </li>
                        </ul>
                      </div>

                      <div class="long">
                        <div class="lat">经度:{{real.longitude|numsfilters(6)}}</div>
                        <div class="lat">纬度:{{real.latitude|numsfilters(6)}}</div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="item">
                        <h3>开始时间</h3>
                        <div class="value">{{real.startTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <h3>记录模式</h3>
                        <div class="value">{{real.recordMode|recordfilters}}</div>
                      </div>
                      <div class="item">
                        <h3>记录间隔</h3>
                        <div class="value">{{real|intervalfilters}}</div>
                      </div>
                      <div class="item">
                        <h3>传感器模式</h3>
                        <div class="value">{{real.deepMode|modefilters}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <h2>深度、电流时间曲线</h2>
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="m-box m-map">
                  <div class="top">
                    <h2>电子地图</h2>
                    <!-- <div class="right">
                      <g-tabs @change="changeMapHandle" :current="mapType" :list="tabList" />
                    </div> -->
                  </div>
                  <div class="con">
                    <div class="mapDiv">
                      <tdt-map :center="coordinates.center" :zoom="coordinates.zoom" :controls="coordinates.controls">
                        <tdt-marker :position="coordinates.position" :icon="coordinates.icon" :draggable="coordinates.draggable"></tdt-marker>
                        <tdt-control position="topright" :visible="coordinates.visible"></tdt-control>
                      </tdt-map>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <!-- <h2>实时数据</h2> -->
                  <div class="m-datab1">
                    <div class="top">
                      <div class="left">
                        <div class="icon" ref="angle">
                        </div>
                        <div class="t1">
                          <p>X<b>{{real.xAngle|num2filters}}</b></p>
                          <p>Y<b>{{real.yAngle|num2filters}}</b></p>
                        </div>
                      </div>
                      <div class="oh">
                        <div class="item">
                          <p>持续时长</p>
                          <b>{{real.lasttime}}分钟</b>
                        </div>
                        <div class="item">
                          <p>最大深度</p>
                          <b>{{real.maxDeep|numfilters}}m</b>
                        </div>
                        <div class="item">
                          <p>填料量</p>
                          <b>{{real.totalWeight|numfilters}}kg</b>
                        </div>
                        <div class="item">
                          <p>垂直度</p>
                          <b>{{real.verticality|numfilters}}%</b>
                        </div>
                      </div>
                    </div>
                    <div class="picbox">
                      <img src="../../assets/images/c-i3.png" class="bg" alt="">
                      <div class="databar">
                        <div class="data" :style="{bottom:ztTop}">
                          <img src="../../assets/images/c-i5.png" alt="" :class="image">
                          <img src="../../assets/images/c-i2.png" alt="" class="zt">
                          <div class="info">
                            <p><b>{{pileStatusStr}}</b></p>
                            <p>深度<em>{{real.deep|numfilters}}(m)</em></p>
                            <p>速度<em>{{real.speed|numfilters}}(cm/min)</em></p>
                            <p>电流<em>{{real.current1|numfilters}}(A)</em></p>
                            <img src="../../assets/images/c-i4.png" alt="" class="line">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="m-tab-table" v-show="tab2 == 1">
        </div>
        <div class="m-tab-table" v-show="tab2 == 2">
        </div>
        <div class="m-tab-table" v-show="tab2 == 3">
          <div class="block">
            <el-carousel trigger="click" :autoplay="false" arrow="never" class="swiper_video">
              <el-carousel-item v-for="(camera,index) in cameraLive" :key="index" class="swiper_item" :label="index+1">
                <video-player style="width:100%;height:100%" ref="videoPlayer" :options="playerOptions[index]"></video-player>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 页码 -->
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="sizes,slot,  prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[4, 8, 12, 16]" :page-size="pageSize" layout="slot , jumper" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import GPS from '../../utils/GpstoGD'
import { lastData, datas, pileIds, editConfig } from '@/api/api'
import * as echarts from "echarts"
import gTabs from "@/components/gTabs"
import $ from 'jquery'
import moment from "moment";
export default ({
  props: ['onlineList', 'iconImg', 'total', 'currentPage4', 'handleCurrentChange', 'handleSizeChange', 'pageSize', 'toLastPage', 'toFirstPage'],
  components: {
    gTabs
  },
  data() {
    return {
      showdesigndiv: false,
      showSubmit: true,
      coordinates: {
        center: [0, 0],
        zoom: 11,
        draggable: false,
        position: [0, 0],
        icon: require("@/assets/images/map/b4.png"),
        controls: [
          "Zoom",
          "Scale",
          {
            name: "MapType",
            position: "topright",
            mapTypes: [
              {
                title: "地图", //地图控件上所要显示的图层名称
                icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png", //地图控件上所要显示的图层图标（默认图标大小 80x80）
                layer: "TMAP_NORMAL_MAP" //地图类型，在原天地图api中以window.TMAP_NORMAL_MAP表示，此处为字符串
              },
              {
                title: "卫星",
                icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
                layer: "TMAP_SATELLITE_MAP"
              },
            ]
          }
        ],
        visible: true,
        copyright: true
      },// 天地图
      cameraLive: [],
      playerOptions: [],
      message: String,
      index: 0,
      recordStatusClass: '',
      image: 'stop',
      depth: 0,
      pileStatusStr: '停钻',
      timer: null,
      deviceKey: '',
      pileId: '',
      pile: [],
      real: {
        'speed': 0,
        'totalWeight': 0,
        'current1': 0,
        'deep': 0,
        'density': 0,
        'deviceKey': "-",
        'longitude': 0,
        'latitude': 0,
        'yAngle': 0,
        'xAngle': 0,
        'verticality': 0,
        'recordInterval': '1',
        'recordMode': ''
      },
      flow: [], //【时间，流量】
      deep: [], //【时间，深度】
      selectItem: [],
      ztTop: 0,//钻头位置
      y: 0.00,//钻头深度
      scale: 1,
      centerDialogVisible: false,
      form: {
        projectName: '',
        worker: '',
        minPileTime: '',
        designDepth: '',
        designAsh: '',
        designPilePadding: '',
        pileWidth: '',
        designUpSpeed: '',
        designDownSpeed: ''
      },
      centerDialogVisible1: false,
      tab2: 0,
      tab2List: [],
      ispileIds: true
    }
  },
  filters: {
    ofiltersPipe(val, val2) {
      if (!val) {
        return '-'
      } else {
        if (val[val2] == null) {
          return '-'
        } else {
          return val[val2]
        }
      }
    },
    datafilters(val) {
      return moment(val * 1000).format('HH:mm:ss')
    },
    numsfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    numfilters(val) {
      if (val == '') {
        return '-'
      } else {
        return parseFloat(val).toFixed(2)
      }
    },
    // num1filters(val) {
    //   return parseFloat(val).toFixed(3)
    // },
    num2filters(val) {
      return parseFloat(val).toFixed(1)
    },
    stafilters(val) {
      switch (val) {
        case '1':
          return '记录中'
        case '2':
          return '暂停'
        case '3':
          return '结束'
        default:
          return val
      }
    },
    recordfilters(val) {
      if (val === '1') {
        return '时间模式'
      } else {
        return '深度模式'
      }
    },
    intervalfilters(val) {
      if (val.recordInterval === '0') {
        if (val.recordMode === '1') {
          return '5s'
        } else {
          return '20cm'
        }
      } else if (val.recordInterval === '1') {
        if (val.recordMode === '1') {
          return '10s'
        } else {
          return '25cm'
        }
      } else if (val.recordInterval === '2') {
        if (val.recordMode === '1') {
          return '30s'
        } else {
          return '50cm'
        }
      } else if (val.recordInterval === '3') {
        if (val.recordMode === '1') {
          return '60s'
        } else {
          return '100cm'
        }
      }
    },
    modefilters(val) {
      if (val === '0') {
        return '单杆模式'
      } else if (val === '1') {
        return '接杆模式'
      } else {
        return 'RTK'
      }
    }
  },
  watch: {
    centerDialogVisible1(val, oldVal) {//普通的watch监听
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (val == false) {
        this.getwatchData()
      }
    },
  },
  mounted() {
    if (sessionStorage['accountType'] != 1) {
      this.showSubmit = false
      this.showdesigndiv = true
    }
  },
  methods: {
    // watch中的数据变量
    getwatchData() {
      this.ispileIds = true
      this.pile = []
      this.defaultpile = ''
      this.real = {
        'current1': 0,
        'deep': 0,
        'density': 0,
        'deviceKey': "-",
        'expandStatus': "0",
        'flow': 0,
        'latitude': 0,
        'longitude': 0,
        'machineKey': "-",
        'maxDeep': 0,
        'nozzleStatus': "0",
        'partAsh': 0,
        'partSlurry': 0,
        'pileDescribe': "-",
        'pileId': 0,
        'pipeStatus': "0",
        'pressure': 0,
        'processType': "00000000",
        'realTime': 0,
        'recordStatus': "3",
        'speed': 0,
        'startTime': 0,
        'totalAsh': 0,
        'totalSlurry': 0,
        'version': "-",
        'waterCementRatio': 0,
        'xAngle': 0,
        'yAngle': 0,
        'lasttime': 0
      }
      this.flow = []
      this.deep = []
      this.depth = 0
      this.pileStatusStr = '停钻'
      this.recordStatusClass = ''
      if (this.myChart01 === undefined || this.myChart01._disposed) return
      this.myChart01.dispose() //监听弹出框在关闭时将flow和deep里边的数据都清空
      this.image = 'stop'
    },
    setAngle(xAngle, yAngle) {
      let angleOption = {
        tooltip: {
          show: false,
          trigger: "axis",
          formatter: 'x: ' + xAngle + '<br />y :' + yAngle
        },
        grid: {
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        xAxis: {
          type: "value",
          min: "-15",
          max: "15",
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          min: "-15",
          max: "15",
          axisLabel: {
            show: false
          }
        },
        series: [{
          symbolSize: 10,
          data: [
            [xAngle, yAngle]
          ],
          type: 'scatter'
        }]
      };
      if (this.angle == undefined) {
        this.angle = echarts.init(this.$refs.angle);
      }
      this.angle.setOption(angleOption);
    },
    initEchart01(show) {
      let option = {
        title: {
          show: show,
          text: "暂无数据",
          textStyle: {
            color: '#101D34',
            fontSize: 16
          },
          left: "center",
          top: 'center'
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "电流"],
        },
        grid: {
          top: '21%',
          left: "4%",
          right: "4%",
          bottom: "12%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: '3%',
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m ",
            type: "value",
            inverse: true,  //设置坐标轴翻转
            nameLocation: 'end',
            max: 30,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "   电流A",
            type: "value",
            inverse: false,
            nameLocation: 'start',
            max: 300,
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: this.deep
          },
          {
            name: "电流",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: this.flow,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      // this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
    },
    // 点击弹出框切换顶部标签
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.$nextTick(() => {
          // this.initEchart01();
          window.onresize = function () {
            self.myChart01.resize();
          };
        });
        this.getData()
      }
      if (index == 1) {
        sessionStorage['type'] = this.selectItem.type
        sessionStorage['deviceKey'] = this.selectItem.key
        this.$router.push('/history')
      }
      if (index == 2) {
        sessionStorage['deviceKey'] = this.selectItem.key
        this.$router.push('/alertInfo')
      }
      if (index == 3) {
        clearInterval(this.timer)
        this.getwatchData()
      }
    },
    // pileIds获取实时桩号
    getPileIds() {
      pileIds(this.selectItem.key).then(res => {
        if (res != null) {
          this.pile = res[0]
          this.ispileIds = false
        }
      })
    },
    // data获取实时数据
    getData() {
      let self = this
      datas(this.selectItem.key, this.pileId, 'realTime,current1,deep,pipeStatus').then(res => {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        for (var i = 0; i < res.length; i++) {
          let day_Time = moment(res[i].realTime * 1000).format('YYYY-MM-DD HH:mm:ss')
          let currentTime = [];
          let deepTime = [];
          currentTime.push(day_Time, res[i].current1.toFixed(2))
          deepTime.push(day_Time, res[i].deep.toFixed(2))
          this.flow.push(currentTime) //电流，时间
          this.deep.push(deepTime) //深度，时间
        }
        // 基于准备好的dom，初始化echarts实例
        this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
        this.initEchart01(false);
        // 获取数组中的最后一个值
        var end = res[res.length - 1]
        if (end != undefined) {
          if (end.pipeStatus == '1') {
            this.pileStatusStr = '下钻'
            this.image = 'down'
          } else if (end.pipeStatus == '2') {
            this.pileStatusStr = '提钻'
            this.image = 'up'
          } else if (end.pipeStatus == '3') {
            this.pileStatusStr = '下钻'
          } else if (end.pipeStatus == '4') {
            this.pilefilter == '提钻'
          }
          this.depth = end.deep
          self.myFun(end.deep);
        }
        this.timer = setInterval(() => {
          lastData(this.selectItem.key, this.pileId).then(res => {
            if (res != null) {
              if (this.ispileIds == true) {
                this.getPileIds()
              }
              this.real = res
              if (this.real.recordStatus == '1') {
                this.recordStatusClass = 'on'
              } else if (this.real.recordStatus == '2') {
                this.recordStatusClass = 'pause'
              } else if (this.real.recordStatus == '3') {
                this.recordStatusClass = ''
                this.ispileIds = true
              }
              if (this.real.deep > this.depth) {
                this.pileStatusStr = '下钻'
                this.pipeStatusClass = 'on'
                this.image = 'down'
              } else if (this.real.deep < this.depth) {
                this.pileStatusStr = '提钻'
                this.pipeStatusClass = 'on'
                this.image = 'up'
              } else {
                this.pipeStatusClass = ''
              }
              this.depth = this.real.deep
              let realDayTime = moment(res.realTime * 1000).format('YYYY-MM-DD HH:mm:ss')
              if (this.flow.length == 0 || this.flow[this.flow.length - 1][0] != realDayTime) {
                let realcurrent1Time = [];
                let realdeepTime = [];
                realcurrent1Time.push(realDayTime, res.current1.toFixed(2))
                realdeepTime.push(realDayTime, res.deep.toFixed(2)) //深度，时间
                this.flow.push(realcurrent1Time)
                this.deep.push(realdeepTime) //深度，时间
                this.initEchart01(false);
              }
              this.real.lasttime = ((res.realTime - res.startTime) / 60).toFixed(0)
              self.myFun(this.real.deep);
              this.setAngle(this.real.xAngle, this.real.yAngle)
            } else {
              this.ispileIds = true
              this.flow = []
              this.deep = []
              this.pile = []
              this.defaultpile = ''
              this.real = {
                'current': 0,
                'deep': 0,
                'density': 0,
                'deviceKey': "-",
                'expandStatus': "0",
                'flow': 0,
                'latitude': 0,
                'longitude': 0,
                'machineKey': "-",
                'maxDeep': 0,
                'nozzleStatus': "0",
                'partAsh': 0,
                'partSlurry': 0,
                'pileDescribe': "-",
                'pileId': 0,
                'pipeStatus': "0",
                'pressure': 0,
                'processType': "00000000",
                'realTime': 0,
                'recordStatus': "3",
                'speed': 0,
                'startTime': 0,
                'totalAsh': 0,
                'totalSlurry': 0,
                'version': "-",
                'waterCementRatio': 0,
                'xAngle': 0,
                'yAngle': 0,
                'lasttime': 0
              }
              this.depth = 0
              this.pileStatusStr = '停钻'
              this.recordStatusClass = ''
              this.image = 'stop'
              this.initEchart01(true);
              this.setAngle(0, 0)
              self.myFun(this.real.deep)
            }
            loading.close();
          })
        }, 3000);
        setTimeout(() => {
          loading.close()
        }, 6000)
      })
    },
    // 点击弹出页面
    handleCommand(command) {
      let self = this;
      let index = command.split('-')[0];
      let index2 = command.split('-')[1];
      this.selectItem = this.onlineList[index]; //将具体点击的字段数据存储在selectItem中
      this.cameraLive = JSON.parse(this.selectItem.cameraLive);
      if (this.cameraLive == null) {
        this.tab2List = [
          {
            name: "运行状况",
          },
          {
            name: "历史记录",
          },
          {
            name: "预警信息",
          }
        ]
      } else {
        this.tab2List = [
          {
            name: "运行状况",
          },
          {
            name: "历史记录",
          }, {
            name: "预警信息",
          }, {
            name: "实时监控",
          }
        ]
        this.playerOptions = [];
        for (let i = 0; i < this.cameraLive.length; i++) {
          let playerOption = {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: true, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
              type: "application/x-mpegURL",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
              src: this.cameraLive[i].liveUrl //url地址
            }],
            notSupportedMessage: '此视频暂无法播放,请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true  //全屏按钮
            }
          }
          this.playerOptions.push(playerOption)
        }
      }

      if (this.selectItem.position == '') {
        this.selectItem.position = '0,0'
      } else {
        this.coordinates.position = [this.selectItem.position.split(',')[0], this.selectItem.position.split(',')[1]]
        this.coordinates.center = [this.selectItem.position.split(',')[0], this.selectItem.position.split(',')[1]]
        if (this.selectItem.onlineStatus == 0) {
          this.coordinates.icon = require("@/assets/images/map/b9.png")
        } else {
          this.coordinates.icon = require("@/assets/images/map/a8.png")
        }
      }
      switch (index2) {
        case '0': {
          this.centerDialogVisible1 = true;
          this.$nextTick(() => {
            // this.initEchart01();
            window.onresize = function () {
              self.myChart01.resize();
            };
          });
          //  pileIds正在施工桩号
          this.getPileIds()
          this.getData()
          break;
        }
        case '1': {
          // console.log('沉管沙桩info实时跳转');
          sessionStorage['type'] = this.selectItem.type
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/history')
          break;
        }
        case '2': {
          sessionStorage['deviceKey'] = this.selectItem.key
          this.$router.push('/alertInfo')
          break;
        }
        case '3': {
          this.$router.push('/previewOnline#')
          break;
        }
        case '4': {
          this.centerDialogVisible = true;
          this.form.projectName = JSON.parse(this.selectItem.content).projectName == undefined ? '' : JSON.parse(this.selectItem.content).projectName
          this.form.worker = JSON.parse(this.selectItem.content).worker == undefined ? '' : JSON.parse(this.selectItem.content).worker
          this.form.minPileTime = JSON.parse(this.selectItem.content).minPileTime == undefined ? '' : JSON.parse(this.selectItem.content).minPileTime
          this.form.designDepth = JSON.parse(this.selectItem.content).designDepth == undefined ? '' : JSON.parse(this.selectItem.content).designDepth
          this.form.designAsh = JSON.parse(this.selectItem.content).designAsh == undefined ? '' : JSON.parse(this.selectItem.content).designAsh
          this.form.designPilePadding = JSON.parse(this.selectItem.content).designPilePadding == undefined ? '' : JSON.parse(this.selectItem.content).designPilePadding
          this.form.pileWidth = JSON.parse(this.selectItem.content).pileWidth == undefined ? '' : JSON.parse(this.selectItem.content).pileWidth
          this.form.designUpSpeed = JSON.parse(this.selectItem.content).designUpSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designUpSpeed
          this.form.designDownSpeed = JSON.parse(this.selectItem.content).designDownSpeed == undefined ? '' : JSON.parse(this.selectItem.content).designDownSpeed
          break;
        }
        // default: {

        // }
      }
    },
    destroyed() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    // 保存设计参数
    onSubmit() {
      editConfig(this.form, this.selectItem.id).then((res) => {
        this.onlineList[this.index].content = JSON.stringify(this.form)
      })
      this.centerDialogVisible = false;
    },
    // 设计参数关闭
    closePop(name) {
      this[name] = false;
    },
    // 钻头
    myFun(y) {
      var that = this;
      that.y = Number(y).toFixed(2);
      that.ztTop = (100 - y / 40 * 100) + '%';
    },
  }
})
</script>
<style scoped>
.long {
  position: absolute;
  top: 50px;
  right: 50px;
  height: 70px;
  width: 180px;
}
.long .lat:first-child {
  border-bottom: none;
}
.lat {
  height: 33.5px;
  line-height: 33.5px;
  text-align: center;
  border: 1px solid rgb(226, 225, 225);
}
.mapDiv {
  height: 100%;
  width: 100%;
}
.m-pop-echart .col,
.m-tab-echartTable {
  height: auto;
}

.m-pop-echart .row {
  display: flex;
}

.m-box {
  height: 100%;
}

.m-pop-echart-f01 .inner1 {
  padding-bottom: 32px;
}
.m-statusinfo .bottom .item {
  width: 25% !important;
}
.m-statusinfo .ul-status {
  float: left;
}
.ul-status li {
  margin-left: 10px;
  margin-left: 0;
}
/* 在线一览 每个模块的高度 */
.ul-previewOnline .inner {
  height: 16rem;
}
/* 在线一览 详情弹出框去除竖向滚动条 */

.m-pop-echart-f01 .inner1 {
  padding: 1.7rem 2rem 0;
}
.m-datab1 .top {
  margin-bottom: 0;
}
.m-datab1 {
  padding: 0.1rem 1.25rem 0rem;
}
.m-datab1.top {
  padding: 0rem 1.125rem;
}
.m-datab1 .top {
  margin-top: 1.25rem;
}
.m-datab1 {
  padding: 0.1rem 1.25rem 0.75rem;
}
.m-pop-echart .col {
  padding: 0.1rem 0.15rem;
}
/* 在线一览 详情弹出框去除竖向滚动条 */
</style>
